import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LoadingLine from 'components/common/loading-line';
import api from 'helpers/api';
import colors from 'helpers/colors';

const tabs = [
  { label: 'Trips', key: 'trips' },
  { label: 'Vouchers', key: 'vouchers' },
  { label: 'Support Tickets', key: 'supportTickets' },
  { label: 'Subscriptions', key: 'subscriptions' },
  { label: 'Reports', key: 'reports' },
  { label: 'Incidents', key: 'incidents' },
  { label: 'Points', key: 'points' },
  { label: 'Plan Applications', key: 'plans' },
  { label: 'Activity', key: 'activity' },
  { label: 'Linked', key: 'linked' },
];

class UserTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: props.user,
      trips: [],
      vouchers: [],
      supportTickets: [],
      subscriptions: [],
      reports: [],
      incidents: [],
      points: [],
      plans: [],
      activity: [],
      linked: [],
    };

    this.setTab = this.setTab.bind(this);
    this.loadTab = this.loadTab.bind(this);

    this.renderTrip = this.renderTrip.bind(this);
    this.renderVoucher = this.renderVoucher.bind(this);
    this.renderSupportTicket = this.renderSupportTicket.bind(this);
    this.renderSubscription = this.renderSubscription.bind(this);
    this.renderIncident = this.renderIncident.bind(this);
    this.renderPlan = this.renderPlan.bind(this);
    this.renderPoints = this.renderPoints.bind(this);
    this.renderActivity = this.renderActivity.bind(this);
    this.renderLinked = this.renderLinked.bind(this);
    this.renderReport = this.renderReport.bind(this);
  }

  componentDidMount() {
    this.setTab('trips');
  }

  setTab(tab) {
    this.setState({ tab, loading: this.state[tab].length === 0 }, this.loadTab);
  }

  loadTab() {
    const userId = this.props.user.id;
    switch (this.state.tab) {
      case 'trips':
        return api.get(`/user/${userId}/trips`)
          .then((res) => this.setState({ loading: this.state.tab !== 'trips', trips: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'vouchers':
        return api.get(`/user/${userId}/vouchers`)
          .then((res) => this.setState({ loading: this.state.tab !== 'vouchers', vouchers: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'supportTickets':
        return api.get(`/user/${userId}/support-tickets`)
          .then((res) => this.setState({ loading: this.state.tab !== 'supportTickets', supportTickets: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'subscriptions':
        return api.get(`/user/${userId}/subscriptions`)
          .then((res) => this.setState({ loading: this.state.tab !== 'subscriptions', subscriptions: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'reports':
        return api.get(`/user/${userId}/reports`)
          .then((res) => this.setState({ loading: this.state.tab !== 'reports', reports: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'incidents':
        return api.get(`/user/${userId}/incidents`)
          .then((res) => this.setState({ loading: this.state.tab !== 'incidents', incidents: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'points':
        return api.get(`/user/${userId}/points`)
          .then((res) => this.setState({ loading: this.state.tab !== 'points', points: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'activity':
        return api.get(`/user/${userId}/activity`)
          .then((res) => this.setState({ loading: this.state.tab !== 'activity', activity: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'linked':
        return api.get(`/user/${userId}/linked`)
          .then((res) => this.setState({ loading: this.state.tab !== 'linked', linked: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'plans':
        return api.get(`/user/${userId}/pricing-plan-applications`)
          .then((res) => this.setState({ loading: this.state.tab !== 'plans', plans: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      default:
        return;
    }
  }

  renderTrip(trip, i) {
    const color = trip.status === 'ACTIVE' ? '#4834d4' : (trip.paymentStatus === 'successful' ? '#999' : '#EB4D4B');
    const fontWeight = (trip.paymentStatus === 'failed' || trip.status === 'ACTIVE') ? '600' : '400';
    return (
      <Link to={{ pathname: `/trip/${trip.id}`, state: { trip } }} key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-title">{ moment(trip.startTime).format('h:mma D/M/YY') }</p>
          <p className="fm-user-tab-row-detail" style={{ color, fontWeight }}>{ (trip.endTime ? (moment(trip.endTime).diff(trip.startTime, 'minutes') + ' mins') : 'Active Now') } - ${ (trip.actualCost / 100).toFixed(2) }</p>
        </div>
      </Link>
    );
  }

  renderVoucher(voucher, i) {
    const active = voucher.remainingValue > 0 && moment().isBefore(voucher.expires);
    const backgroundColor = active ? colors.voucher[voucher.type] : '#ced6e0';
    const valueDescriptor = (voucher) => {
      if (voucher.type === 'CREDIT') {
        return `$${(voucher.remainingValue / 100)} of $${(voucher.totalValue / 100)}`;
      } else if (voucher.type === 'MINUTES') {
        return `${voucher.remainingValue} of ${voucher.totalValue} mins`;
      } else {
        return `${voucher.remainingValue} of ${voucher.totalValue} unlocks`;
      }
    };
    const restrictionsDescriptor = (voucher) => {
      const vehicleMap = { 'SCOOTER': 'Scooters', 'BIKE': 'Bikes' };
      if (voucher.vehicleType) {
        if (voucher.region) {
          return `${vehicleMap[voucher.vehicleType]} in ${voucher.region.name} only. `;
        }
        return `${vehicleMap[voucher.vehicleType]} only. `;
      } else if (voucher.region) {
        return `${voucher.region.name} only. `;
      }
      return;
    };
    return (
      <div key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-tag" style={{ backgroundColor }}>{ voucher.code }</p>
          <p className="fm-user-tab-row-title">Expires { moment(voucher.expires).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-user-tab-row-detail">{ valueDescriptor(voucher) } remaining. { restrictionsDescriptor(voucher) }Redeemed at { moment(voucher.redeemedAt).format('h:mma D/M/YY') }.</p>
      </div>
    );
  }

  renderSupportTicket(ticket, i) {
    const status = ticket.resolvedAt ? 'resolved' : 'unresolved';
    return (
      <Link to={`/support/ticket/${ticket.id}`} key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-tag" style={{ backgroundColor: colors.ticket[status] }}>{ status.toUpperCase() }</p>
          <p className="fm-user-tab-row-title">{ moment(ticket.createdAt).fromNow() }</p>
        </div>
        <p className="fm-user-tab-row-detail">{ ticket.subject }</p>
      </Link>
    );
  }

  renderSubscription(subscription, i) {
    return (
      <Link to={{ pathname: `/subscription/user/${subscription.id}`, state: { subscription } }} key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-tag" style={{ backgroundColor: colors.subscription[subscription.status] }}>{ subscription.status.toUpperCase() }</p>
          <p className="fm-user-tab-row-title">{ moment(subscription.startDate).format('D/M/YY') } to { moment(subscription.expires).format('D/M/YY') }</p>
        </div>
        <p className="fm-user-tab-row-detail">Go Pass { subscription.plan.name }{ subscription.autoRenew ? ' (Auto Renews)' : '' }</p>
      </Link>
    );
  }

  renderIncident(incident, i) {
    return (
      <Link to={{ pathname: `/incident/${incident.id}`, state: { incident } }} key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-tag" style={{ backgroundColor: colors.incident[incident.type] }}>{ incident.type.toUpperCase() }</p>
          <p className="fm-user-tab-row-title">{ moment(incident.startTime).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-user-tab-row-detail">{ incident.status.toUpperCase() } - { incident.vehicleRegistration }</p>
      </Link>
    );
  }

  renderPoints(points, i) {
    const active = points.remainingPoints > 0 && moment().isBefore(points.expiresAt);
    const backgroundColor = active ? colors.points[points.type] : '#ced6e0';
    return (
      <div key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-tag" style={{ backgroundColor }}>{ points.type.toUpperCase() }</p>
          <p className="fm-user-tab-row-title">Expires { moment(points.expiresAt).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-user-tab-row-detail">{ points.remainingPoints } of { points.points } remaining. Earned at { moment(points.createdAt).format('h:mma D/M/YY') }.{ points.objective ? ` ${points.objective.name}.` : '' }</p>
      </div>
    );
  }

  renderActivity(activity, i) {
    return (
      <div key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <a className="fm-user-tab-row-tag" key={i} target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${activity.latitude},${activity.longitude}`} style={{ backgroundColor: colors.activity[activity.type] }}>{ activity.type }</a>
          <p className="fm-user-tab-row-title">{ moment(activity.eventTime).format('h:mma D/M/YY') }</p>
        </div>
        <Link to={ activity.vehicleId ? `/vehicle/${activity.vehicleId}` : '#' } className="fm-vehicle-tab-row-detail">
          { this.renderActivityBlurb(activity) }
        </Link>
      </div>
    );
  }

  renderActivityBlurb(activity) {
    switch(activity.type) {
      case 'SCAN':
        return `Scanned vehicle ${activity.registration}.`;
      case 'VIEW':
        return `Viewed vehicle ${activity.registration}.`;
      case 'RIDE_START':
        return `Ride started on vehicle ${activity.registration}.`;
      case 'RIDE_END':
        return `Ride ended on vehicle ${activity.registration}.`;
      default:
        return `Vehicle ${activity.registration}.`;
    }
  }

  renderLinked(user, i) {
    return (
      <Link to={{ pathname: `/user/${user.id}`, state: { user } }} key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-title">{ user.firstName } { user.lastName }</p>
          <p className="fm-user-tab-row-detail">{ user.email } - { user.linked.charAt(0).toUpperCase() + user.linked.slice(1) }</p>
        </div>
      </Link>
    );
  }

  renderReport(report, i) {
    return (
      <Link to={{ pathname: `/report/${report.id}`, state: { report } }} key={i} className="fm-trip-tab-row">
        <div className="fm-trip-tab-row-content">
          <p className="fm-trip-tab-row-tag" style={{ backgroundColor: colors.report[report.type.category] }}>{ report.type.category.toUpperCase() }</p>
          <p className="fm-trip-tab-row-title">{ moment(report.createdAt).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-trip-tab-row-detail">{ report.type.title } - { report.notes || 'No notes.' }</p>
      </Link>
    );
  }

  renderPlan(application, i) {
    return (
      <a href={application.identificationPhotoUrl} target="_blank" rel="noreferrer" key={i} className="fm-user-tab-row">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-tag" style={{ backgroundColor: colors.plans[application.status] }}>{ application.status.toUpperCase() }</p>
          <p className="fm-user-tab-row-title">Submitted at { moment(application.createdAt).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-user-tab-row-detail">{ application.plan }</p>
      </a>
    );
  }

  render() {
    const { loading, tab, trips, vouchers, supportTickets, subscriptions, reports, incidents, points, plans, activity, linked } = this.state;
    return (
      <>
        <div className="fm-user-tabs">
          { tabs.map((t, i) => <div key={i} className={`fm-user-tab${ tab === t.key ? ' fm-user-tab-active' : '' }`} onClick={() => this.setTab(t.key)}>{ t.label }</div>) }
        </div>
        <div className="fm-user-tabs-loading">
          { loading && <LoadingLine /> }
        </div>
        <div className="fm-user-tab-content">
          { (tab === 'trips') && ((trips.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No trips found.</p> : trips.map(this.renderTrip)) }
          { (tab === 'vouchers') && ((vouchers.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No vouchers found.</p> : vouchers.map(this.renderVoucher)) }
          { (tab === 'supportTickets') && ((supportTickets.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No support tickets found.</p> : supportTickets.map(this.renderSupportTicket)) }
          { (tab === 'subscriptions') && ((subscriptions.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No subscriptions found.</p> : subscriptions.map(this.renderSubscription)) }
          { (tab === 'reports') && ((reports.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No reports found.</p> : reports.map(this.renderReport)) }
          { (tab === 'incidents') && ((incidents.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No incidents found.</p> : incidents.map(this.renderIncident)) }
          { (tab === 'points') && ((points.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No points found.</p> : points.map(this.renderPoints)) }
          { (tab === 'plans') && ((plans.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No pricing plan applications found.</p> : plans.map(this.renderPlan)) }
          { (tab === 'activity') && ((activity.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No activity found.</p> : activity.map(this.renderActivity)) }
          { (tab === 'linked') && ((linked.length === 0 && !loading) ? <p className="fm-user-tab-content-empty">No linked users found.</p> : linked.map(this.renderLinked)) }
          { !tab && <p className="fm-user-tab-content-empty">Select a tab above.</p> }
        </div>
      </>
    );
  }
}

export default UserTabs;
