const status = {
  'INUSE': '#00A8FF',
  'AVAILABLE': '#4CD137',
  'UNAVAILABLE': '#718093',
  'RESERVED': '#FBC531',
  'CHARGING': '#FBC531',
  'MAINTENANCE': '#E84118',
  'TRANSIT': '#2F3640',
  'DEMO': '#A55EEA',
  'REPAIR': '#F0932B',
  'IOT': '#CCCCCC',
  'OFFLINE': '#CCCCCC',
};

const helmet = {
  'unlocked': '#3498db',
  'locked': '#2ecc71',
  'returned': '#2ecc71',
  'fined': '#e74c3c',
  'dismissed': '#34495e',
  'added': '#f1c40f',
};

const ticket = {
  'resolved': '#6AB04C',
  'unresolved': '#EB4D4B',
  'open': '#F0932B',
  'unassigned': '#EB4D4B',
};

const voucher = {
  'CREDIT': '#A3CB38',
  'MINUTES': '#12CBC4',
  'UNLOCKS': '#D980FA',
};

const cost = {
  'subscription': '#A3CB38',
  'voucher': '#12CBC4',
  'adjustment': '#D980FA',
  'hireage': '#126bcb',
  'unlock': '#cb9012',
};

const task = {
  'deploy': '#00a8ff',
  'pickup': '#EB3B5A',
  'batterySwap': '#4B7BEC',
  'rebalance': '#f39c12',
  'repark': '#f1c40f',
  'charge': '#1abc9c',
  'inspection': '#A55EEA',
  'helmet': '#A3CB38',
};

const vehicleTicket = {
  'note': '#f0932b',
  'maintenance': '#eb4d4b',
  'missing': '#f1c40f',
  'accident': '#eb4d4b',
  'movement': '#f0932b',
  'theft': '#e056fd',
  'noParking': '#f1c40f',
  'parking': '#f1c40f',
};

const taskStatus = {
  'reserved': '#f1c40f',
  'available': '#20BF6B',
  'collected': '#f1c40f',
  'expired': '#999',
  'completed': '#000000',
  'cancelled': '#999999',
};

const inspection = {
  'PASS': '#6AB04C',
  'FAIL': '#EB4D4B',
};

const performanceActivity = {
  'available': status.AVAILABLE,
  'on_trip': status.INUSE,
  'removed': task.rebalance,
  'inspection': task.inspection,
  'batteryOpen': task.batterySwap,
  'report': '#f1c40f',
};

const subscription = {
  'pending': '#7f8fa6',
  'active': '#4cd137',
  'scheduled': '#00a8ff',
  'finished': '#7f8fa6',
  'cancelled': '#fbc531',
};

const payment = {
  'pending': '#7f8fa6',
  'successful': '#4cd137',
  'failed': '#e84118',
};

const incident = {
  'nearMiss': '#f1c40f',
  'incident': '#eb4d4b',
  'theft': '#e056fd',
  'vandalism': '#f0932b',
};

const firmware = {
  'IoT': '#00a8ff',
  'BMS': '#fbc531',
  'ECU': '#e84118',
  'Meter': '#4cd137',
  'Repeater/Cable lock': '#fa8231',
  'DISPLAY': '#4cd137',
  'Display': '#4cd137',
};

const zone = {
  'NORIDING': '#D0021B',
  'NOHIRE': '#D0021B',
  'NOPARKING': '#F1C40F',
  'LOWSPEED': '#E67E22',
  'LOWSPEEDNOPARKING': '#E67E22',
  'PREFERREDPARKING': '#7ED321',
  'PUBLICTRANSPORT': '#3498DB',
  'DESIGNATED': '#3498DB',
  'HIDDEN': '#CCCCCC',
  'REMINDER': '#CCCCCC',
  'serviceArea': '#ff206e',
};

const points = {
  'trip': '#12CBC4',
  'objective': '#D980FA',
};

const activity = {
  'SCAN': 'rgb(0 168 255 / 50%)',
  'VIEW': 'rgb(76 209 55 / 50%)',
  'RIDE_START': '#00A8FF',
  'RIDE_END': '#4CD137',
};

const support = {
  'REPORT': '#000000',
  'REVIEW': '#000000',
  'EMAIL': '#000000',
  'CALL': '#4834D4',
  'WEBSITE': '#000000',
  'OTHER': '#000000',
  'APP': '#000000',
  'STAFF': '#16a085',
};

const report = {
  'zone': '#f0932b',
  'riding': '#00A8FF',
  'parking': '#f1c40f',
  'helmet': '#10AC84',
};

const reportStatus = {
  'pending': '#f0932b',
  'dismissed': '#999999',
  'actioned': '#20BF6B',
  'resolved': '#20BF6B',
};

const performanceTimeliness = {
  'firstActivity': '#00a8ff',
  'lastActivity': '#9c88ff',
  'clockIn': '#4cd137',
  'clockOut': '#fbc531',
  'outOfHours': '#e67e22',
};

const statisticTypes = {
  'scooter': '#FF206E',
  'bike': '#2f3640',
};

const regionEvents = {
  restrictions: '#f1c40f',
  demand: '#00A8FF',
  risks: '#EB3B5A',
};

const plans = {
  pending: '#95a5a6',
  approved: '#2ecc71',
  declined: '#e74c3c',
  overwritten: '#95a5a6',
};

const appVersion = {
  latest: '#6AB04C',
  outdated: '#EB4D4B',
};

const colors = { status, helmet, ticket, voucher, task, taskStatus, vehicleTicket, inspection, performanceActivity, subscription, payment, incident, firmware, zone, points, activity, support, report, cost, reportStatus, performanceTimeliness, statisticTypes, regionEvents, plans, appVersion };

export default colors;
