import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LoadingLine from 'components/common/loading-line';
import api from 'helpers/api';
import colors from 'helpers/colors';

const tabs = [
  { label: 'Trips', key: 'trips' },
  { label: 'Alerts', key: 'alerts' },
  { label: 'Inspections', key: 'inspections' },
  { label: 'Tasks', key: 'tasks' },
  { label: 'Tickets', key: 'tickets' },
  { label: 'Status Changes', key: 'statusChanges' },
  { label: 'Activity', key: 'activity' },
  { label: 'Faults', key: 'faults' },
  { label: 'Helmet Lock', key: 'helmetLock' },
  { label: 'Cable Lock', key: 'mechanicalLock' },
];

const statusColors = {
  available: '#4CD137',
  removed: '#2F3640',
  non_operational: '#E84118',
  on_trip: '#00A8FF',
  elsewhere: '#E84118',
  unknown: '#E84118',
  'RESERVED': '#00A8FF',
  'AVAILABLE': '#4CD137',
  'UNAVAILABLE': '#718093',
  'REMOVED': '#FBC531',
  'INUSE': '#00A8FF',
};

const typeColors = {
  'SCAN': 'rgb(0 168 255 / 50%)',
  'VIEW': 'rgb(76 209 55 / 50%)',
  'RIDE_START': '#00A8FF',
  'RIDE_END': '#4CD137',
};

const typeBlurbs = {
  'SCAN': 'Vehicle scanned by',
  'VIEW': 'Vehicle viewed by',
  'RIDE_START': 'A trip was started by',
  'RIDE_END': 'A trip was ended by',
};

const reasonFrom = (change, user) => {
  switch (change) {
    case 'provider_drop_off':
      return user ? `This vehicle was deployed by ${user.firstName} ${user.lastName}.` : `This vehicle was deployed.`;
    case 'trip_end':
      return user ? `${user.firstName} ${user.lastName} ended their trip on this vehicle.` : `A trip ended on this vehicle.`;
    case 'trip_cancel':
      return user ? `${user.firstName} ${user.lastName} cancelled their trip on this vehicle.` : `A trip was cancelled on this vehicle.`;
    case 'trip_start':
      return user ? `${user.firstName} ${user.lastName} began their trip on this vehicle.` : `A trip began on this vehicle.`;
    case 'reservation_start':
      return user ? `${user.firstName} ${user.lastName} reserved this vehicle.` : `A reservation began on this vehicle.`;
    case 'reservation_cancel':
      return user ? `${user.firstName} ${user.lastName} cancelled their reservation on this vehicle.` : `A reservation was cancelled on this vehicle.`;
    case 'rebalance_pick_up':
      return user ? `This vehicle was picked up for rebalancing by ${user.firstName} ${user.lastName}.` : `This vehicle was picked up for rebalancing.`;
    case 'maintenance_pick_up':
      return user ? `This vehicle was picked up for maintenance by ${user.firstName} ${user.lastName}.` : `This vehicle was picked up for maintenance.`;
    case 'decommissioned':
      return user ? `${user.firstName} ${user.lastName} decommissioned this vehicle.` : `This vehicle was decommissioned.`;
    case 'battery_charged':
      return user ? `${user.firstName} ${user.lastName} deployed this vehicle after charging/changing the battery.` : `This vehicle was made available due battery charging.`;
    case 'battery_low':
      return `This vehicle was made unavailable due to low battery.`;
    case 'maintenance':
      return user ? `This vehicle was disabled by ${user.firstName} ${user.lastName} due to maintenance.` : `This vehicle was disabled due to maintenance.`;
    default:
      return change;
  }
};

const descriptionFor = (task) => {
  switch (task.status) {
    case 'available':
      return task.expiresAt ? `Expires ${ moment(task.expiresAt).fromNow() }.` : `Available.`;
    case 'reserved':
      return `Reserved by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }. Expires ${ moment(task.expiresAt).fromNow() }.`;
    case 'collected':
      return `Collected by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }.${ task.completeBy ? ` Due ${moment(task.completeBy).fromNow() }.` : '' }`;
    case 'completed':
      return `Completed ${ task.assignedTo ? `by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName } ` : '' }at ${ moment(task.completedAt).format('h:mma D/M/YY') }.`;
    case 'expired':
      return `Task expired. ${ task.notes ? task.notes : '' }`;
    case 'cancelled':
      return `Task cancelled.`;
    default:
      return ``;
  }
};

class VehicleTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      trips: [],
      alerts: [],
      inspections: [],
      activity: [],
      statusChanges: [],
      tasks: [],
      tickets: [],
      faults: [],
      helmetLock: [],
      mechanicalLock: [],
    };

    this.setTab = this.setTab.bind(this);
    this.loadTab = this.loadTab.bind(this);

    this.renderTrip = this.renderTrip.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
    this.renderInspection = this.renderInspection.bind(this);
    this.renderActivity = this.renderActivity.bind(this);
    this.renderStatusChange = this.renderStatusChange.bind(this);
    this.renderTask = this.renderTask.bind(this);
    this.renderTicket = this.renderTicket.bind(this);
    this.renderFault = this.renderFault.bind(this);
    this.renderHelmetLock = this.renderHelmetLock.bind(this);
    this.renderMechanicalLock = this.renderMechanicalLock.bind(this);
  }

  setTab(tab) {
    this.setState({ tab, loading: this.state[tab].length === 0 || this.state[tab] === false }, this.loadTab);
  }

  loadTab() {
    const vehicleId = this.props.id;
    switch (this.state.tab) {
      case 'trips':
        return api.get(`/vehicle/${vehicleId}/rides`)
          .then((res) => this.setState({ loading: this.state.tab !== 'trips', trips: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'alerts':
        return api.get(`/vehicle/${vehicleId}/alerts`)
          .then((res) => this.setState({ loading: this.state.tab !== 'alerts', alerts: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'inspections':
        return api.get(`/vehicle/${vehicleId}/inspections`)
          .then((res) => this.setState({ loading: this.state.tab !== 'inspections', inspections: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'activity':
        return api.get(`/vehicle/${vehicleId}/activity`)
          .then((res) => this.setState({ loading: this.state.tab !== 'activity', activity: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'helmetLock':
        return api.get(`/vehicle/${vehicleId}/helmet-lock`)
          .then((res) => this.setState({ loading: this.state.tab !== 'helmetLock', helmetLock: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'mechanicalLock':
        return api.get(`/vehicle/${vehicleId}/mechanical-lock`)
          .then((res) => this.setState({ loading: this.state.tab !== 'mechanicalLock', mechanicalLock: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'statusChanges':
        return api.get(`/vehicle/${vehicleId}/status-changes`)
          .then((res) => this.setState({ loading: this.state.tab !== 'statusChanges', statusChanges: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'tasks':
        return api.get(`/task/all?vehicleId=${vehicleId}`)
          .then((res) => this.setState({ loading: this.state.tab !== 'tasks', tasks: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'tickets':
        return api.get(`/ticket/all?vehicleId=${vehicleId}&type=maintenance`)
          .then((res) => this.setState({ loading: this.state.tab !== 'tickets', tickets: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'faults':
        return api.get(`/vehicle/${vehicleId}/faults`)
          .then((res) => this.setState({ loading: this.state.tab !== 'faults', faults: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      default:
        return;
    }
  }

  renderTrip(trip, i) {
    return (
      <Link to={{ pathname: `/trip/${trip.id}`, state: { trip } }} key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-title">{ moment(trip.startTime).format('h:mma D/M/YY') }</p>
          <p className="fm-vehicle-tab-row-detail">{ (trip.endTime ? (moment(trip.endTime).diff(trip.startTime, 'minutes') + ' mins') : 'Active Now') }</p>
        </div>
      </Link>
    );
  }

  renderAlert(alert, i) {
    return (
      <a className="fm-vehicle-tab-row" key={i} target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${alert.latitude},${alert.longitude}`}>
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-title">{ alert.alert }</p>
          <p className="fm-vehicle-tab-row-detail">{ moment(alert.occurredAt).format('h:mma D/M/YY') }</p>
        </div>
      </a>
    );
  }

  renderInspection(inspection, i) {
    return (
      <Link to={`/inspection/${inspection.id}`} key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-title">{ moment(inspection.completedAt).format('h:mma D/M/YY') }</p>
          <p className="fm-vehicle-tab-row-tag" style={{ backgroundColor: colors.inspection[inspection.result] }}>{ inspection.result }</p>
        </div>
        <p className="fm-vehicle-tab-row-detail">{ inspection.notes }</p>
      </Link>
    );
  }

  renderActivity(activity, i) {
    return (
      <div key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <a className="fm-vehicle-tab-row-tag" key={i} target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${activity.latitude},${activity.longitude}`} style={{ backgroundColor: typeColors[activity.type] }}>{ activity.type }</a>
          <p className="fm-vehicle-tab-row-title">{ moment(activity.eventTime).format('h:mma D/M/YY') }</p>
        </div>
        <Link to={ activity.userId ? `/user/${activity.userId}` : '#' } className="fm-vehicle-tab-row-detail">
          { typeBlurbs[activity.type] } { activity.firstName } { activity.lastName }.
        </Link>
      </div>
    );
  }

  renderStatusChange(change, i) {
    return (
      <div key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-tag" style={{ backgroundColor: statusColors[change.vehicleState] }}>{ change.vehicleState.toUpperCase() }</p>
          <a className="fm-vehicle-tab-row-title" target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${change.eventLatitude},${change.eventLongitude}`}>{ moment(change.eventTime).format('h:mma D/M/YY') }</a>
        </div>
        <p className="fm-vehicle-tab-row-detail">{ reasonFrom(change.eventType, change.user) } { parseInt(change.batteryPercent * 100) }%.</p>
      </div>
    );
  }

  renderTask(task, i) {
    return (
      <Link to={`/task/${task.id}`} key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-tag" style={{ backgroundColor: colors.task[task.type] }}>{ task.type.toUpperCase() }</p>
          <p className="fm-vehicle-tab-row-title">{ moment(task.createdAt).fromNow() }</p>
        </div>
        <p className="fm-vehicle-tab-row-detail">{ descriptionFor(task) }</p>
      </Link>
    );
  }

  renderTicket(ticket, i) {
    return (
      <Link to={`/ticket/${ticket.id}`} key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-tag" style={{ backgroundColor: colors.vehicleTicket[ticket.type] }}>{ ticket.type.toUpperCase() }</p>
          <p className="fm-vehicle-tab-row-title">{ moment(ticket.createdAt).fromNow() }</p>
        </div>
        <p className="fm-vehicle-tab-row-detail">{ ticket.description }</p>
      </Link>
    );
  }

  renderFault(fault, i) {
    return (
      <div key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-title">{ fault.faultCode }</p>
          <p className="fm-vehicle-tab-row-detail">{ moment(fault.occurredAt).format('h:mma D/M/YY') }</p>
        </div>
      </div>
    );
  }

  renderHelmetLock(change, i) {
    return (
      <Link to={`/helmet-lock/${change.helmetLock.id}`} key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-tag" style={{ backgroundColor: colors.helmet[change.eventType] }}>{ change.eventType.toUpperCase() }</p>
          <p className="fm-vehicle-tab-row-title">{ moment(change.eventTime).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-vehicle-tab-row-detail">{ change.eventType.charAt(0).toUpperCase() + change.eventType.slice(1) } { change.user ? `by ${change.user.firstName} ${change.user.lastName}` : '' } - { parseInt(change.batteryPercent * 100) }%.</p>
      </Link>
    );
  }

  renderMechanicalLock(change, i) {
    return (
      <div key={i} className="fm-vehicle-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-tag" style={{ backgroundColor: colors.helmet[change.status] }}>{ change.status.toUpperCase() }</p>
          <p className="fm-vehicle-tab-row-title">{ moment(change.createdAt).format('h:mma D/M/YY') }</p>
        </div>
      </div>
    );
  }

  render() {
    const { loading, tab, trips, alerts, inspections, activity, statusChanges, tasks, tickets, faults, helmetLock, mechanicalLock } = this.state;
    return (
      <>
        <div className="fm-vehicle-tabs">
          { tabs.map((t, i) => <div key={i} className={`fm-vehicle-tab${ tab === t.key ? ' fm-vehicle-tab-active' : '' }`} onClick={() => this.setTab(t.key)}>{ t.label }</div>) }
        </div>
        <div className="fm-vehicle-tabs-loading">
          { loading && <LoadingLine /> }
        </div>
        <div className="fm-vehicle-tab-content">
          { (tab === 'trips' && trips) && trips.map(this.renderTrip) }
          { (tab === 'alerts' && alerts) && alerts.map(this.renderAlert) }
          { (tab === 'inspections' && inspections) && inspections.map(this.renderInspection) }
          { (tab === 'tasks' && tasks) && tasks.map(this.renderTask) }
          { (tab === 'tickets' && tickets) && tickets.map(this.renderTicket) }
          { (tab === 'activity' && activity) && activity.map(this.renderActivity) }
          { (tab === 'statusChanges' && statusChanges) && statusChanges.map(this.renderStatusChange) }
          { (tab === 'faults' && faults) && faults.map(this.renderFault) }
          { (tab === 'helmetLock' && helmetLock) && helmetLock.map(this.renderHelmetLock) }
          { (tab === 'mechanicalLock' && mechanicalLock) && mechanicalLock.map(this.renderMechanicalLock) }
          { !tab && <p className="fm-vehicle-tab-content-empty">Select a tab above.</p> }
        </div>
      </>
    );
  }
}

export default VehicleTabs;
