import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class AnalyticsKPIs extends Component {
  constructor() {
    super();
    const today = moment().format('YYYY-MM')
    this.state = { month: today, today, data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    document.title = 'KPIs | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const month = this.state.month;
    return api.post('/analytics/kpis', { month })
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleDateChange(e) {
    this.setState({ month: e.target.value });
  }

  render() {
    const { loading, data, month, today } = this.state;

    return (
      <div className="fm-analytics">
        <NavigationBar title="KPIs" loading={loading} />
        <div className="fm-analytics-kpis">
          <div className="fm-analytics-kpis-form">
            <input value={month} className="fm-input" type="month" onChange={this.handleDateChange} max={today} />
            <button className="fm-analytics-kpis-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Load Data' }</button>
          </div>
          {
            data ? (
              <table className="fm-analytics-kpis-table">
                {
                  Object.values(data.regions).map((region, i) => {
                    const activeDays = region.dailyActive.reduce((a, c) => { a[c.activeDate] = (a[c.activeDate] || 0) + c.maxActive; return a; }, {});
                    const averageActive = Object.values(activeDays).reduce((t, c) => (t += c / region.cap), 0) / Object.values(activeDays).length;
                    return (
                      <tbody key={i}>
                        <tr>
                          <td className="fm-analytics-kpis-table-label"><b>{ region.name }</b></td>
                          <td className="fm-analytics-kpis-table-value"></td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-kpis-table-label">Active Scooters ({region.cap})</td>
                          <td className="fm-analytics-kpis-table-value">{ (averageActive * 100).toFixed(2) }%</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-kpis-table-label">Scan Fails</td>
                          <td className="fm-analytics-kpis-table-value">{ (region.failedScans * 100).toFixed(2) }%</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-kpis-table-label">Hub Deployments</td>
                          <td className="fm-analytics-kpis-table-value">{ region.hubDeploys }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-kpis-table-label">Average Task Resolution Time</td>
                          <td className="fm-analytics-kpis-table-value">{ region.taskAverageDuration }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-kpis-table-label">Labour Hours</td>
                          <td className="fm-analytics-kpis-table-value">{ (region.totalHours).toFixed(2) }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-kpis-table-label">Labour Hours / Revenue</td>
                          <td className="fm-analytics-kpis-table-value">{ (region.labourCostRatio * 100).toFixed(2) }%</td>
                        </tr>
                        {
                          region.managerPerformance.map((manager, ii) => {
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-kpis-table-label">{ manager.name } - Average Points Per Hour</td>
                                <td className="fm-analytics-kpis-table-value">{ manager.avgPointsPerHour } ({ manager.totalPoints }p / { (manager.totalHours).toFixed(2) }h)</td>
                              </tr>
                            );
                          })
                        }
                        <tr>
                          <td className="fm-analytics-operations-table-label"></td>
                          <td className="fm-analytics-operations-table-value"></td>
                        </tr>
                      </tbody>
                    );
                  })
                }
              </table>
            ) : (
              <p className="fm-analytics-kpis-empty">{ loading ? 'Please wait...' : 'Select the month above.' }</p>
            )
          }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsKPIs;
