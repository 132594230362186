import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class DocumentVersion extends Component {
  constructor(props) {
    super(props);
    const preloadedVersion = props.location.state ? props.location.state.version : false;
    this.state = {
      version: preloadedVersion || {},
      loading: true,
      editing: false,
      error: false,
    };
    this.loadVersion = this.loadVersion.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRevert = this.onRevert.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeMarkdown = this.onChangeMarkdown.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const { version } = this.state;
    if (version) {
      if (version.documentTemplate) {
        document.title = `${version.documentTemplate.title} - ${version.subtitle} | Flamingo Admin`;
      } else {
        document.title = `${version.subtitle} | Flamingo Admin`;
      }
    } else {
      document.title = 'Document Version | Flamingo Admin';
    }
    this.loadVersion();
  }

  loadVersion() {
    return api.get(`/document/version/${this.props.match.params.versionId}`)
      .then((res) => this.setState({ version: { ...this.state.version, ...res.data.data }, loading: false }))
      .catch(this.handleError);
  }

  onDownload() {
    this.setState({ loading: true });
    return api.post(`/document/version/${this.props.match.params.versionId}/download`)
      .then(this.handleFile)
      .catch(this.handleError);
  }

  onSave() {
    this.setState({ loading: true });
    const { version } = this.state;
    return api.post(`/document/version/${version.id}`, { markdown: version.markdown })
      .then((res) => this.setState({ version: { ...version, ...res.data.data, originalMarkdown: undefined }, loading: false }))
      .catch(this.handleError);
  }

  onRevert() {
    const { version } = this.state;
    version.markdown = version.originalMarkdown;
    this.setState({ version });
  }

  handleFile(res) {
    const isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);
    this.setState({ loading: false }, () => isIOS ? (window.location = res.data.data.url) : window.open(res.data.data.url));
  }

  onChangeStatus(e) {
    const generateDate = e.target.value;
    this.setState({ generateDate });
  }

  onChangeMarkdown(e) {
    const markdown = e.target.value;
    const { version } = this.state;
    if (!version.originalMarkdown) {
      version.originalMarkdown = version.markdown;
    }
    version.markdown = markdown;
    this.setState({ version });
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? `Invalid ${e.response.data.validation[0]}` : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, loading: false });
  }

  render() {
    const { loading, error, version } = this.state;
    const title = version.documentTemplate ? version.documentTemplate.title : (version.subtitle || 'Document Version');
    const hasEdited = !!version.originalMarkdown && version.originalMarkdown !== version.markdown;

    return (
      <div className="fm-document-version">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-document-version-content">
          <div className="fm-document-version-details">
            <div className="fm-document-version-details-row">
              <p className="fm-document-version-details-row-label">Start Date</p>
              <p className="fm-document-version-details-row-value">{ moment(version.startDate).format('D/M/YY') }</p>
            </div>
            <div className="fm-document-version-details-row">
              <p className="fm-document-version-details-row-label">End Date</p>
              <p className="fm-document-version-details-row-value">{ moment(version.endDate).format('D/M/YY') }</p>
            </div>
            <div className="fm-document-version-details-row">
              <p className="fm-document-version-details-row-label">Created At</p>
              <p className="fm-document-version-details-row-value">{ moment(version.createdAt).format('h:mma D/M/YY') }</p>
            </div>
            {
              hasEdited ? (
                  <div className="fm-document-version-details-buttons">
                    <button onClick={this.onSave} disabled={loading} className="fm-document-version-details-button">Save</button>
                    <button onClick={this.onRevert} disabled={loading} className="fm-document-version-details-button fm-document-version-details-button-destructive">Revert</button>
                  </div>
              ) : (
                <div className="fm-document-version-details-buttons">
                  <button onClick={this.onDownload} disabled={loading} className="fm-document-version-details-button">Download</button>
                </div>
              )
            }
            {
              (version.status === 'draft' && version.documentTemplate && version.documentTemplate.notes) && <p className="fm-document-version-details-notes">Notes: { version.documentTemplate.notes }</p>
            }
          </div>
          <div className="fm-document-version-markdown">
            <textarea className="fm-document-version-markdown-textarea" value={version.markdown} disabled={loading} onChange={this.onChangeMarkdown}></textarea>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default DocumentVersion;
