import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Input from 'components/common/input';
import history from 'helpers/history';
import colors from 'helpers/colors';
import api from 'helpers/api';

const types = { incident: 'Incident', nearMiss: 'Near Miss', vandalism: 'Vandalism', theft: 'Theft' };
const statuses = { open: 'Open', closed: 'Closed' };
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const years = ['2019', '2020', '2021', '2022', '2023', '2024', '2025'];

class IncidentEvents extends Component {
  constructor(props) {
    super(props);
    const query = props.match.params.query ? decodeURIComponent(props.match.params.query) : '';
    this.state = {
      results: [],
      statistics: {},
      recent: [],
      regions: [],
      query,
      loading: true,
      type: 'all',
      status: 'open',
      month: 'all',
      regionId: 'all',
    };

    this.loadRecent = this.loadRecent.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.loadStatistics = this.loadStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.search = throttle(500, this.search);
    this.renderIncident = this.renderIncident.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Incidents | Flamingo Admin';
    if (this.state.query.length > 0) {
      this.search(this.state.query);
    }
    this.loadRecent();
    this.loadRegions();
    this.loadStatistics();
  }

  // Networking

  loadRecent() {
    const { type, status, month, regionId } = this.state;
    let endpoint = '/incident/recent';
    const params = [];
    if (type !== 'all') {
      params.push(`type=${type}`);
    }
    if (status === 'closed') {
      params.push(`status=${status}`);
    }
    if (month !== 'all') {
      params.push(`month=${moment(month, 'MMMM YYYY').format('YYYY-MM-DD')}`);
    }
    if (regionId !== 'all') {
      params.push(`regionId=${regionId}`);
    }
    if (params.length > 0) {
      endpoint += `?${params.join('&')}`
    }
    api.get(endpoint)
      .then((res) => this.setState({ recent: res.data.data, loading: this.state.query.length > 0 }))
      .catch(this.handleError);
  }

  loadStatistics() {
    api.get('/incident/statistics')
      .then((res) => this.setState({ statistics: res.data.data }))
      .catch(this.handleError);
  }

  loadRegions() {
    return api.get('/region/all')
      .then((res) => this.setState({ regions: res.data.data }))
      .catch(this.handleError);
  }

  search(query) {
    if (query.length < 2) {
      history.replace(`/incident`);
      this.setState({ loading: false });
      return;
    }
    history.replace(`/incident/search/${encodeURIComponent(query)}`)
    api.post('/incident/search', { query })
      .then((res) => this.setState({ results: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Handlers

  handleSearchChange(query) {
    this.setState({ loading: true, query }, () => {
      this.search(query);
    });
  }

  handleTypeChange(e) {
    this.setState({ type: e.target.value, loading: true }, this.loadRecent);
  }

  handleStatusChange(e) {
    this.setState({ status: e.target.value, loading: true }, this.loadRecent);
  }

  handleMonthChange(e) {
    this.setState({ month: e.target.value, loading: true }, this.loadRecent);
  }

  handleRegionChange(e) {
    this.setState({ regionId: e.target.value, loading: true }, this.loadRecent);
  }

  // Renders

  renderIncident(incident, i) {
    return (
      <Link to={{ pathname: `/incident/${incident.id}`, state: { incident } }} key={i}>
        <div className="fm-incident-events-result">
          <p className="fm-incident-events-result-title">{ types[incident.type] } - { moment(incident.startTime).format('dddd, Do MMM YYYY') }</p>
          <div className="fm-incident-events-result-bottom">
            <div className="fm-incident-events-result-dot" style={{ backgroundColor: colors.incident[incident.type] }}></div>
            { incident.status && <p className="fm-incident-events-result-item">{ incident.status }</p> }
            { incident.severity && <p className="fm-incident-events-result-item">&#xb7; { incident.severity }</p> }
            { incident.name && <p className="fm-incident-events-result-item">&#xb7; { incident.name }</p> }
            { incident.vehicleRegistration && <p className="fm-incident-events-result-item">&#xb7; { incident.vehicleRegistration }</p> }
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, query, results, recent, regions, statistics, error, type, status, month, regionId } = this.state;

    const newIncidentAction = { to: '/incident/new', icon: 'plus-circle' };

    return (
      <div className="fm-incident-events">
        <NavigationBar title="Incidents" loading={loading} rightActions={[newIncidentAction]} />
        <div className="fm-incident-events-content">
          <div className="fm-incident-events-stats">
            <div className="fm-incident-events-stats-open">
              <p className="fm-incident-events-stats-open-text">Open <span className="fm-incident-events-stats-value">{ statistics.total || 0 }</span></p>
            </div>
            <div className="fm-incident-events-stats-types">
              {
                Object.keys(types).map((type, i) => {
                  return (
                    <div key={i} className="fm-incident-events-stats-type">
                      <div className="fm-incident-events-stats-type-dot" style={{ backgroundColor: colors.incident[type] }}></div>
                      <p className="fm-incident-events-stats-type-text">{ types[type] } <span className="fm-incident-events-stats-value">{ statistics[type] || 0 }</span></p>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div className="fm-incident-events-filter">
            <div className="fm-incident-events-filter-group">
              <select value={type} onChange={this.handleTypeChange} className="fm-input fm-input-select">
                <option value="all">All Types</option>
                { Object.keys(types).map((type, i) => <option key={i} value={type}>{types[type]}</option>) }
              </select>
              <select value={status} onChange={this.handleStatusChange} className="fm-input fm-input-select">
                { Object.keys(statuses).map((status, i) => <option key={i} value={status}>{statuses[status]}</option>) }
              </select>
            </div>
            <div className="fm-incident-events-filter-group">
              <select value={month} onChange={this.handleMonthChange} className="fm-input fm-input-select">
                <option value="all">All Months</option>
                { years.map((year, i) => months.map((month, ii) => <option key={`${month} ${year}`} value={`${month} ${year}`}>{`${month} ${year}`}</option>)) }
              </select>
              <select value={regionId} onChange={this.handleRegionChange} className="fm-input fm-input-select">
                <option value="all">All Regions</option>
                { regions.map((region, i) => <option key={i} value={region.id}>{region.name}</option>) }
              </select>
            </div>
          </div>
          <div className="fm-incident-events-search-bar">
            <Input placeholder="Search for an incident" value={query} autoFocus={true} onChange={this.handleSearchChange} />
          </div>
          <div className="fm-incident-events-results">
            {
              query.length > 0 ? (
                <>
                  { results.map(this.renderIncident) }
                  { (!loading && results.length === 0 && query.length > 0) && <p className="fm-incident-events-results-empty">No incidents found</p> }
                </>
              ) : (
                recent.map(this.renderIncident)
              )
            }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default IncidentEvents;
