import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker, Polyline, Polygon } from 'components/common/map';
import Icon from 'components/common/icon';
import history from 'helpers/history';
import colors from 'helpers/colors';
import api from 'helpers/api';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  start: { url: baseUrl + 'trip-start.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  end: { url: baseUrl + 'trip-end.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  active: { url: baseUrl + 'vehicle-inuse.png', size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) },
};

const actionWording = {
  emailTemplate: 'The rider was sent a template email.',
  emailWarning: 'The rider was sent a fine warning template email.',
  email: 'The rider was emailed.',
  call: 'The rider was called.',
  dismiss: 'The report was dismissed.',
  fine: 'The rider was issued a fine.',
  suspend: 'The rider was suspended.',
  disable: 'The rider was disabled.',
  resolved: 'The report was resolved.',
};

const actionDescription = {
  emailTemplate: 'Template Email',
  emailWarning: 'Fine Warning Email',
  email: 'Email',
  call: 'Call',
  fine: 'Fine',
  dismiss: 'Dismiss',
  suspend: 'Suspend',
  disable: 'Disable',
  resolve: 'Resolve',
};

const actionGuide = {
  emailTemplate: 'A Template Email includes an overview of the report and how the user can improve (if required).',
  emailWarning: 'Send a Fine Warning Email when you are unable to contact the user by calling them.',
  email: 'Send an email when the report needs to be worded differently to a template.',
  call: 'Always make calls with a friendly educational approach. Discuss the implications of their actions and how they can improve. If there is no answer, leave a voicemail and send a Fine Warning Email.',
  fine: 'Always make sure the report is valid before fining. If you are not entirely sure the report is valid, potentially Dismiss or send a Template Email instead. Do not issue more than 1 fine per day per user.',
  dismiss: 'Dismiss the report if it is not valid.',
  suspend: 'Suspensions last 7 days. Please check with a manager before suspending an account.',
  disable: 'Disabling is the last resort. Please check with a manager before disabling an account.',
  resolve: 'Resolve duplicate reports that have already been actioned.',
};

const escalationTypes = {
  emailTemplate: ['emailTemplate', 'email'],
  call: ['emailWarning', 'call'],
  fine: ['fine'],
  suspend: ['suspend'],
  disable: ['disable'],
};

const fineStatuses = {
  pending: 'Pending',
  success: 'Successful',
  failed: 'Failed',
  overwritten: 'Overwritten',
};

const typeWarning = {
  zone: 'Zone Reports must be carefully reviewed. GPS data alone cannot be relied upon when reviewing these reports. Verify the parking location using the parking photo and Google Street View. If unsure, click here for the manual.',
  helmet: 'Helmet Reports must be carefully reviewed. Always check the parking photo to see if the helmet was returned, and review the previous parking photo or any open support tickets for context. If unsure, click here for the manual.',
};

class ReportView extends Component {
  constructor(props) {
    super(props);
    const report = { trip: {}, user: {}, ...(props.location.state ? props.location.state.report : {}) };
    this.state = { report, history: [], supportTickets: [], actionNotes: '', photo: 'report', loading: true };
    this.loadReport = this.loadReport.bind(this);
    this.loadHistory = this.loadHistory.bind(this);
    this.loadSupportTickets = this.loadSupportTickets.bind(this);
    this.calculateRecommendation = this.calculateRecommendation.bind(this);
    this.handleRecommendationInfo = this.handleRecommendationInfo.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleActionResponse = this.handleActionResponse.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.handleSnooze = this.handleSnooze.bind(this);
    this.handlePhotoReport = this.handlePhotoReport.bind(this);
    this.handlePhotoParking = this.handlePhotoParking.bind(this);
    this.nextReport = this.nextReport.bind(this);
    this.handleNextReport = this.handleNextReport.bind(this);
    this.handleActionNotesChange = this.handleActionNotesChange.bind(this);
    this.onTrip = this.onTrip.bind(this);
  }

  componentDidMount() {
    document.title = 'Trip Report | Flamingo Admin';
    this.loadReport();
  }


  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadReport();
    }
  }

  loadReport() {
    this.setState({ loading: true });
    return api.get(`/report/${this.props.match.params.reportId}`)
      .then((res) => this.setState({ report: res.data.data, history: [], photo: res.data.data.photoUrl ? 'report' : 'parking' }, () => this.loadHistory(res.data.data.id, res.data.data.user.id, res.data.data.trip.id)))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  loadHistory(reportId, userId, tripId) {
    this.loadSupportTickets(userId, tripId);
    return api.get(`/user/${userId}/reports?linked=true`)
      .then((res) => this.setState({ history: res.data.data.filter((i) => i.id !== reportId && ['pending', 'actioned', 'resolved'].includes(i.status)), loading: false }, this.calculateRecommendation))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  loadSupportTickets(userId, tripId) {
    return api.get(`/user/${userId}/support-tickets`)
      .then((res) => this.setState({ supportTickets: res.data.data.filter((t) => !t.resolvedAt || (t.associatedTrip && t.associatedTrip.id === tripId)) }))
      .catch({});
  }

  calculateRecommendation() {
    const { report, history } = this.state;
    const escalation = report.type.escalation;
    const dayAgo = moment().subtract(1, 'day');
    const monthsAgo = moment().subtract(3, 'months');
    const stepRequiresRecentAction = ['fine', 'suspend', 'disable'];
    const actionSorter = (a, b) =>  a.actionedAt > b.actionedAt ? 1 : a.actionedAt < b.actionedAt ? -1 : 0;
    const typeHistory = history.filter((r) => r.type.id === report.type.id && r.zoneId === report.zoneId && r.status === 'actioned').sort(actionSorter);
    const lastAction = typeHistory.length > 0 ? typeHistory[typeHistory.length - 1] : false;
    const recommendation = { action: 'emailTemplate' };

    if (lastAction && moment(report.createdAt).isBefore(lastAction.actionedAt) && lastAction.action !== 'emailTemplate') {
      recommendation.action = 'emailTemplate';
      recommendation.grace = <>This report was created before the most recent report for this type was actioned. <b>Discretion should be applied</b>.</>;

    } else {

      var historyOffset = 0;
      for (var i = 0; i < escalation.length; i++) {
        const nextEscalation = escalation[i];

        if (i + 1 === escalation.length) {
          if (stepRequiresRecentAction.includes(nextEscalation)) {
            if (lastAction) {
              if (monthsAgo.isAfter(lastAction.actionedAt)) {
                recommendation.action = escalation[i - 1];
                recommendation.grace = <>The <b>recommended escalation action has been reduced</b> as the user hasn't been contacted about this report type in the past 3 months.</>;
              } else if (dayAgo.isBefore(lastAction.actionedAt)) {
                recommendation.action = escalation[i - 1];
                recommendation.grace = <>The <b>recommended escalation action has been reduced</b> as the user has already been contacted in the last 24 hours.</>;
              } else {
                recommendation.action = nextEscalation;
              }
            }
          } else {
            recommendation.action = nextEscalation;
          }
          break;
        }

        let included = false;
        while (historyOffset < typeHistory.length) {
          const previousAction = typeHistory[historyOffset];

          ++historyOffset;

          if (escalationTypes[nextEscalation].includes(previousAction.action)) {
            included = true;
            break;
          }
        }

        if (!included) {
          if (stepRequiresRecentAction.includes(nextEscalation)) {
            if (lastAction) {
              if (monthsAgo.isAfter(lastAction.actionedAt)) {
                recommendation.action = escalation[i - 1];
                recommendation.grace = <>The <b>recommended escalation action has been reduced</b> as the user hasn't been contacted about this report type in the past 3 months.</>;
              } else if (dayAgo.isBefore(lastAction.actionedAt)) {
                recommendation.action = escalation[i - 1];
                recommendation.grace = <>The <b>recommended escalation action has been reduced</b> as the user has already been contacted in the last 24 hours.</>;
              } else {
                recommendation.action = nextEscalation;
              }
              break;
            }
          } else {
            recommendation.action = nextEscalation;
            break;
          }

        }
      }
    }

    if (typeHistory.length === 0) {
      recommendation.reason = 'This is the first report for this type.';
    } else {
      const occurrences = typeHistory.reduce((acc, curr) => { acc[curr.action] ? ++acc[curr.action] : acc[curr.action] = 1; return acc; }, {});
      const occurrenceString = Object.keys(occurrences).map((o) => `${occurrences[o]} ${actionDescription[o]}${occurrences[o] > 1 ? 's' : ''}`).join(', ');
      recommendation.reason = `The user has previously received ${occurrenceString} for this ${ report.zoneId ? 'zone' : 'report type' }.`;
    }

    this.setState({ recommendation, action: recommendation.action });
  }

  handleRecommendationInfo() {
    const { report } = this.state;
    const lastEscalation = actionDescription[report.type.escalation[report.type.escalation.length - 1]];
    if (report.type.escalation.length === 1) {
      return window.alert(`There are no escalation steps for ${report.type.title} reports, each report should be actioned with a ${lastEscalation}.`);
    }
    if (report.type.escalation.length === 2) {
      const firstEscalation = actionDescription[report.type.escalation[0]];
      return window.alert(`There are two escalation steps for ${report.type.title} reports. The first report should be actioned with a ${firstEscalation}, then all additional reports with a ${lastEscalation}.`);
    }
    const steps = report.type.escalation.slice(0, -1).map((e, i) => `${i + 1}. ${actionDescription[e]}`).join(`\n`);
    return window.alert(`The escalation steps for ${report.type.title} reports are: \n${steps} \n\nAfter this, all additional reports should be actioned with a ${lastEscalation}.`);
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  handleActionChange(e) {
    const action = e.target.value;
    this.setState({ action });
  }

  handleActionNotesChange(e) {
    const actionNotes = e.target.value;
    this.setState({ actionNotes });
  }

  handleAction() {
    const { submitting, action, actionNotes, report, photo } = this.state;
    const actions = {
      note: 'update the note for this report',
      call: 'mark this report as called',
      email: 'create a support ticket for this report',
      emailTemplate: `send a template email (using the ${ photo === 'report' ? 'team' : 'parking' } photo) for this report`,
      emailWarning: `send a fine warning email (using the ${ photo === 'report' ? 'team' : 'parking' } photo) for this report`,
      fine: 'fine this user $15',
      suspend: 'suspend this user for 7 days',
      disable: 'disable this user',
      dismiss: 'dismiss this report',
      resolve: 'resolve this report',
    };
    if (!actions[action] || submitting) {
      return;
    }
    const confirmAction = window.confirm(`Are you sure you want to ${actions[action]}?`);
    if (confirmAction === true) {
      this.setState({ submitting: true });
      const data = { action, photo };
      if (actionNotes.length > 0) {
        data.notes = actionNotes;
      }
      return api.post(`/report/${report.id}`, data)
        .then(this.handleActionResponse)
        .catch((e) => this.setState({ loading: false, submitting: false }, () => alert(this.parseError(e))));
    }
  }

  handleActionResponse(res) {
    const report = { ...this.state.report, ...res.data.data };
    if (report.supportTicket) {
      return history.push(`/support/ticket/${report.supportTicket.id}`)
    }
    this.setState({ report, actionNotes: '', submitting: false });
  }

  handleNote() {
    const { actionNotes, report } = this.state;
    if (actionNotes.length === 0) {
      return;
    }

    this.setState({ submitting: true });
    return api.post(`/report/${report.id}/activity`, { content: actionNotes })
      .then(this.handleActionResponse)
      .catch((e) => this.setState({ loading: false, submitting: false }, () => alert(this.parseError(e))));
  }

  handleSnooze() {
    const { actionNotes, report } = this.state;
    const data = actionNotes.length > 0 ? { content: actionNotes } : false;

    const confirmAction = window.confirm(`Are you sure you want to snooze this report for 12 hours?`);
    if (confirmAction === true) {
      this.setState({ submitting: true });
      return api.post(`/report/${report.id}/snooze`, data)
        .then(() => history.push('/report'))
        .catch((e) => this.setState({ loading: false, submitting: false }, () => alert(this.parseError(e))));
    }
  }

  handlePhotoParking() {
    this.setState({ photo: 'parking' });
  }

  handlePhotoReport() {
    this.setState({ photo: 'report' });
  }

  openManual() {
    window.open('/manual/39');
  }

  onTrip(e) {
    if (e.shiftKey) {
      e.preventDefault();
      const { trip } = this.state.report;
      if (trip.vehicle) {
        return api.get(`/vehicle/${trip.vehicle.id}/trips?limit=40`)
          .then((res) => {
            const previousTrip = res.data.data.find((t) => t.id < trip.id);
            if (previousTrip) {
              return window.open(`/trip/${previousTrip.id}`);
            }
            return alert('Previous Trip Not Found');
          })
          .catch((e) => alert(this.parseError(e)));
      }
      return alert('Previous Trip Not Found');
    }
  }

  nextReport() {
    this.setState({ loading: true, recommendation: null });
    let endpoint = `/report/pending`;
    if (this.props.match.params.filterType) {
      if (this.props.match.params.filterType === 'category') {
        endpoint += `?category=${this.props.match.params.filterValue}`;
      } else {
        endpoint += `?recommendedAction=${this.props.match.params.filterValue}`;
      }
    }
    return api.get(endpoint)
      .then(this.handleNextReport)
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  handleNextReport(res) {
    const currentReport = this.state.report;
    const { filterType, filterValue } = this.props.match.params;
    const reports = res.data.data;
    if (reports.length === 0) {
      return this.setState({ loading: false }, () => alert('No more reports!'));
    }
    const nextReport = reports.find((report) => new Date(report.trip.startTime) > new Date(currentReport.trip.startTime)) || reports[0];
    if (filterType && filterValue) {
      return history.replace(`/report/${nextReport.id}/${filterType}/${filterValue}`);
    }
    return history.replace(`/report/${nextReport.id}`);
  }

  renderActivity(activity, i) {
    return (
      <div className="fm-report-view-activity-item" key={i}>
        <p className="fm-report-view-activity-item-content">{ activity.content }</p>
        <p className="fm-report-view-activity-item-details">{ activity.user.firstName } { activity.user.lastName } - { moment(activity.createdAt).format('h:mma D/M/YY') }</p>
      </div>
    );
  }

  renderHistory(report, i) {
    return (
      <Link to={{ pathname: `/report/${report.id}`, state: { report } }} key={i} className="fm-report-view-history-item">
        <div className="fm-report-view-history-item-header">
          <div className="fm-report-view-history-item-tag">
            <p className="fm-report-view-history-item-status" style={{ backgroundColor: colors.report[report.type.category] }}>{ report.type.category.toUpperCase() }</p>
            <p className="fm-report-view-history-item-action">{ report.action ? report.action.toUpperCase() : report.status.toUpperCase() }</p>
          </div>
          <p className="fm-report-view-history-item-date">{ moment(report.createdAt).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-report-view-history-item-text">{ report.type.title }{ report.notes ? ` - ${report.notes}` : '' }{ report.actionNotes ? ` - ${report.actionNotes}` : '' }</p>
      </Link>
    );
  }

  render() {
    const { loading, submitting, report, history, supportTickets, recommendation, action, actionNotes, photo } = this.state;

    let mapOptions = null;
    if (report.trip.endLatitude && report.trip.endLongitude) {
      mapOptions = { center: { lat: report.trip.endLatitude, lng: report.trip.endLongitude }, zoom: 14 };
    }

    const title = report.type ? `${report.type.title} Report` : 'Trip Report';
    const subtitle = report.type ? report.type.category.toUpperCase() : undefined;

    const actions = [
      { onClick: this.openManual, icon: 'question-circle', title: 'View manual' },
    ];
    if (report.supportTicket) {
      actions.push({ to: `/support/ticket/${report.supportTicket.id}`, icon: 'life-ring', title: 'View support ticket' });
    }
    if (report.type && report.status === 'pending') {
      actions.push({ to: `/report/${ report.id }/change/${ report.type.category }`, icon: 'exchange-alt', title: 'Change report type' });
    }
    if (report.trip.id) {
      actions.push({ to: `/trip/${ report.trip.id }`, onClick: this.onTrip, title: 'View trip (hold shift for previous trip)', icon: 'route' });
    }
    actions.push({ onClick: this.nextReport, icon: 'forward', title: 'View next report' });

    const pendingReports = history.filter((r) => r.status === 'pending');

    return (
      <div className="fm-report-view">
        <NavigationBar title={title} subtitle={subtitle} loading={loading} rightActions={actions} showBack={true} />
        <div className="fm-report-view-content">
          <div className="fm-report-view-details">
            <div className="fm-report-view-tag">
              { report.action && <p className="fm-report-view-details-action">{ report.action.toUpperCase() }</p> }
              { report.status && <p className="fm-report-view-details-status" style={{ backgroundColor: colors.reportStatus[report.status] }}>{ report.status.toUpperCase() }</p> }
            </div>
            <div className="fm-report-view-user">
              { report.user.firstName && <Link className="fm-report-view-user-name" to={{ pathname: `/user/${report.user.id}`, state: { user: report.user } }}>{ report.user.firstName } { report.user.lastName }</Link> }
              { report.user.email && <a className="fm-report-view-user-email" href={`mailto:${report.user.email}`} target="_blank" rel="noreferrer">{ report.user.email }</a> }
              { report.user.phone && <Link className="fm-report-view-user-phone" to={`/call/${report.user.phone}`}>{ report.user.phone }</Link> }
              { (report.user.plan && report.user.plan !== 'STANDARD') && <p className="fm-report-view-user-plan">{ report.user.plan }</p> }
              { report.user.notes && <p className="fm-report-view-user-notes">{ report.user.notes }</p> }
            </div>
            <div className="fm-report-view-trip">
              { report.trip.startTime && <Link className="fm-report-view-trip-times" to={{ pathname: `/trip/${report.trip.id}`, state: { trip: report.trip } }}>{ moment(report.trip.startTime).format('h:mma') } to { moment(report.trip.endTime || undefined).format('h:mma D/M/YY') } { report.trip.vehicle ? `(${report.trip.vehicle.registration})` : '' }</Link> }
              { report.zone && <p className="fm-report-view-trip-zone-name">{ report.zone.name }</p> }
              { report.zone && <p className="fm-report-view-trip-zone-message">{ report.zone.message }</p> }
            </div>
            <div className="fm-report-view-info">
              { (report.notes || report.type) && <p className="fm-report-view-notes">{ report.notes || report.type.description }</p> }
              { report.createdBy && <p className="fm-report-view-created-by">Reported by { report.createdBy.firstName } { report.createdBy.lastName }</p> }
              { report.createdAt && <p className="fm-report-view-created-at">Reported at { moment(report.createdAt).format('h:mma D/M/YY') }</p> }
            </div>
            <div className="fm-report-view-activity">
              { (report.activity || []).map(this.renderActivity) }
            </div>
            {
              report.status !== 'pending' ? (
                <div className="fm-report-view-action">
                  <p className="fm-report-view-notes">{ report.actionNotes || actionWording[report.status === 'resolved' ? 'resolved' : (report.action || 'dismiss')] }</p>
                  { report.fineStatus && <p className="fm-report-view-created-by">Fine Status: { fineStatuses[report.fineStatus] } ({ report.fineAttempts } attempts)</p> }
                  { report.actionedBy && <p className="fm-report-view-created-by">Actioned by { report.actionedBy.firstName } { report.actionedBy.lastName }</p> }
                  { report.actionedAt && <p className="fm-report-view-created-at">Actioned at { moment(report.actionedAt).format('h:mma D/M/YY') }</p> }
                </div>
              ) : (
                <div className="fm-report-view-actions">
                  <div className="fm-report-view-actions-notes">
                    <input className="fm-input" onChange={this.handleActionNotesChange} value={actionNotes} placeholder="Add a Note" />
                    <button className="fm-report-view-actions-notes-button" onClick={this.handleNote} title="Add a note">
                      <Icon icon={ submitting ? 'hourglass-half' : 'sticky-note' } />
                    </button>
                    {
                      (report.status === 'pending' && !submitting) &&
                      <button className="fm-report-view-actions-notes-button" onClick={this.handleSnooze} title="Snooze for 12 hours">
                        <Icon icon="calendar-plus" />
                      </button>
                    }
                  </div>
                  <select className="fm-input fm-input-select" disabled={submitting} defaultValue={ action ? undefined : -1 } value={action} onChange={this.handleActionChange}>
                    <option value="-1" disabled="disabled">Select an Action</option>
                    <option value="dismiss">Dismiss</option>
                    <option value="emailTemplate">Send Template Email</option>
                    <option value="call">Mark as Called</option>
                    { (report.type && report.type.warningEmailTemplate) && <option value="emailWarning">Send Fine Warning Email (Unable to Call)</option> }
                    { (report.type && report.type.fineEmailTemplate) && <option value="fine">Fine ($15)</option> }
                    <option value="email">Email (Create Support Ticket)</option>
                    { (report.type && report.type.suspendEmailTemplate) && <option value="suspend">Suspend (7 Days)</option> }
                    { (report.type && report.type.disableEmailTemplate) && <option value="disable">Disable (Permanent)</option> }
                    <option value="resolve">Resolve (Duplicate)</option>
                  </select>
                  <button className="fm-report-view-action-button" onClick={this.handleAction} disabled={submitting}>{ submitting ? 'Loading...' : 'Submit' }</button>
                  { typeWarning[report.type.category] && <p className="fm-report-view-action-warning" onClick={this.openManual}>{ typeWarning[report.type.category] }</p> }
                </div>
              )
            }
            {
              (report.status === 'pending' && recommendation) &&
              <div className="fm-report-view-recommendation">
                <div className="fm-report-view-recommendation-header">
                  <p className="fm-report-view-recommendation-title">Recommended Action</p>
                  <button className="fm-report-view-recommendation-info" onClick={this.handleRecommendationInfo}>
                    <Icon icon="info-circle" />
                  </button>
                </div>
                <p className="fm-report-view-recommendation-text"><b>{ actionDescription[recommendation.action] }</b> - { actionGuide[recommendation.action] }</p>
                { recommendation.grace && <p className="fm-report-view-recommendation-text">{ recommendation.grace }</p> }
                <p className="fm-report-view-recommendation-text">{ recommendation.reason }</p>
                { pendingReports.length > 0 && <p className="fm-report-view-recommendation-text">The user has <span className="fm-report-view-recommendation-text-highlight">{ pendingReports.length }</span> other pending report{ pendingReports.length === 1 ? '' : 's' }.</p> }
                { supportTickets.length > 0 && <p className="fm-report-view-recommendation-text">The user has <span className="fm-report-view-recommendation-text-highlight">{ supportTickets.length }</span> open/associated support ticket{ supportTickets.length === 1 ? '' : 's' }.</p> }
              </div>
            }
            <div className="fm-report-view-history">
              <p className="fm-report-view-history-title">Report History</p>
              <div className="fm-report-view-history-items">
                { history.map(this.renderHistory) }
                { history.length === 0 && <p className="fm-report-view-history-empty">No other reports.</p> }
              </div>
            </div>
          </div>
          <div className="fm-report-view-media">
            <div className="fm-report-view-images">
              <div className="fm-report-view-images-parking" onClick={this.handlePhotoParking}>
                {
                  report.trip.parkingPhotoUrl ? (
                    <>
                      <img className="fm-report-view-images-parking-img" alt="Parking" src={report.trip.parkingPhotoUrl} />
                      <div className={ `fm-report-view-images-tag` + (photo === 'parking' ? ' fm-report-view-images-tag-selected' : '') }>
                        <p className="fm-report-view-images-tag-text">Parking Photo</p>
                      </div>
                    </>
                  ) : (
                    <p className="fm-report-view-images-missing">No parking photo.</p>
                  )
                }
              </div>
              {
                report.photoUrl &&
                <div className="fm-report-view-images-report" onClick={this.handlePhotoReport}>
                  <img className="fm-report-view-images-report-img" alt="Report" src={report.photoUrl} />
                  <div className={ `fm-report-view-images-tag` + (photo === 'report' ? ' fm-report-view-images-tag-selected' : '') }>
                    <p className="fm-report-view-images-tag-text">Team Photo</p>
                  </div>
                </div>
              }
            </div>
            { (report.status === 'pending' && report.photoUrl && report.trip.parkingPhotoUrl) && <p className="fm-report-view-images-note">If applicable, the <b>{ photo === 'report' ? 'team' : `rider's parking` } photo</b> will be used on an email template. To change this, click the desired photo. { photo === 'report' && <>If the team photo is clearly in a <b>different location</b> to the parking photo, <b>do not send</b> the report.</> }</p> }
            {
              mapOptions &&
              <div className="fm-report-view-map">
                <Map options={mapOptions} google={window.google}>
                  { (report.trip.startLatitude && report.trip.startLongitude) && <Marker position={{ lat: report.trip.startLatitude, lng: report.trip.startLongitude }} title="Start" icon={icons.start} /> }
                  { (report.trip.endLatitude && report.trip.endLongitude) && <Marker position={{ lat: report.trip.endLatitude, lng: report.trip.endLongitude }} title="End" icon={icons.end} /> }
                  { report.trip.encodedPolyline && <Polyline encodedPath={report.trip.encodedPolyline} strokeColor={'#ff206e'} strokeWeight={'4'} /> }
                  { (report.zone && report.zone.polygon) && <Polygon paths={ report.zone.polygon.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone[report.zone.type]} strokeWeight={2} fillColor={colors.zone[report.zone.type]} fillOpacity={0.2} /> }
                </Map>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ReportView;
