import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import FileUploader from 'components/common/file-uploader';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import colors from 'helpers/colors';
import api from 'helpers/api';

const types = { incident: 'Incident', nearMiss: 'Near Miss', vandalism: 'Vandalism', theft: 'Theft' };
const statuses = { open: { label: 'Open', color: '#eb4d4b' }, closed: { label: 'Closed', color: '#20bf6b' } };
const activities = { opened: { icon: 'star', color: '#eb3b5a' }, closed: { icon: 'check-circle', color: '#20bf6b' }, insights: { icon: 'chart-bar', color: '#4B7BEC' }, updated: { icon: 'caret-square-up', color: '#f0932b' }, note: { icon: 'sticky-note', color: '#f1c40f' }, image: { icon: 'image', color: '#f1c40f' }, inspection: { icon: 'tools', color: '#A55EEA' } };

class IncidentView extends Component {
  constructor() {
    super();
    this.state = { incident: {}, activityFileUrl: false, activityContent: '', activityInsights: false, loading: true, error: false };

    this.loadIncident = this.loadIncident.bind(this);
    this.onContentChange = this.onContentChange.bind(this);
    this.onInsightsChange = this.onInsightsChange.bind(this);
    this.onFile = this.onFile.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitActivity = this.submitActivity.bind(this);
    this.handleActivityResponse = this.handleActivityResponse.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActivity = this.renderActivity.bind(this);
  }

  componentDidMount() {
    document.title = 'Incident Details | Flamingo Admin';
    this.loadIncident();
  }

  // Networking

  loadIncident() {
    this.setState({ error: false, loading: true });
    return api.get('/incident/' + this.props.match.params.id)
      .then((res) => this.setState({ incident: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  onDownload() {
    this.setState({ loading: true });
    return api.post(`/incident/${this.props.match.params.id}/download`)
      .then(this.handleFile)
      .catch(this.handleError);
  }

  handleFile(res) {
    const isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);
    this.setState({ loading: false }, () => isIOS ? (window.location = res.data.data.url) : window.open(res.data.data.url));
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? `Invalid ${e.response.data.validation[0]}` : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, loading: false });
  }

  // Handlers

  handleSubmit() {
    const { activityFileUrl, activityContent, activityInsights } = this.state;
    if (!activityContent.length && !activityFileUrl.length) {
      return this.setState({ error: 'Content is required.', loading: false });
    }
    this.setState({ error: false, loading: true });
    const endpoint = `/incident/${this.props.match.params.id}/activity`;
    return this.submitActivity('text', endpoint, activityContent, activityInsights)
      .then(() => this.submitActivity('image', endpoint, activityFileUrl, activityInsights))
      .then(() => this.setState({ activityFileUrl: false, activityContent: '', activityInsights: false, loading: false }))
      .catch(this.handleError);
  }

  submitActivity(type, endpoint, content, insightsVisible = false) {
    if (!content || content.length === 0) {
      return Promise.resolve();
    }
    return api.post(endpoint, { type, content, insightsVisible })
      .then(this.handleActivityResponse);
  }

  handleActivityResponse(res) {
    const { incident } = this.state;
    incident.activity.push(res.data.data);
    this.setState({ incident });
    return Promise.resolve();
  }

  onContentChange(e) {
    this.setState({ activityContent: e.target.value });
  }

  onInsightsChange(e) {
    this.setState({ activityInsights: e.target.checked });
  }

  onFile(file) {
    this.setState({ activityFileUrl: file.url });
  }

  // Renders

  renderActivity(item, i) {
    const icon = activities[item.subtype];
    return (
      <div className="fm-incident-view-activity-item" key={i}>
        <div className="fm-incident-view-activity-item-icon" style={{ color: icon.color }}>
          <Icon icon={icon.icon} />
          { item.insightsVisible && <span className="fm-incident-view-activity-item-icon-insights" title="Visible on Insights"></span> }
        </div>
        <div className="fm-incident-view-activity-item-details">
          { item.type === 'text' && <p className="fm-incident-view-activity-item-text">{ item.content }</p> }
          { item.type === 'event' && <p className="fm-incident-view-activity-item-event">{ item.content }</p> }
          { item.type === 'image' && <img className="fm-incident-view-activity-item-image" alt="Feed" src={ item.content } /> }
          {
            item.createdBy ? (
              <p className="fm-incident-view-activity-item-bottom">{ item.createdBy.firstName } { item.createdBy.lastName } - { moment(item.createdAt).format('h:mma D/MM/YY') }</p>
            ) : (
              <p className="fm-incident-view-activity-item-bottom">{ moment(item.createdAt).format('h:mma D/MM/YY') }</p>
            )
          }
        </div>
      </div>
    );
  }

  render() {
    const { loading, incident, activityContent, activityInsights, error } = this.state;

    const downloadAction = { onClick: this.onDownload, icon: 'file-pdf', title: 'Download' };
    const editAction = { to: `/incident/${incident.id}/edit`, icon: 'edit', title: 'Edit' };
    const refreshAction = { onClick: this.loadIncident, icon: 'sync-alt', title: 'Refresh' };

    let title = "Incident Details";
    if (incident.type && incident.startTime) {
      title = `${types[incident.type] } - ${moment(incident.startTime).format('dddd, Do MMM YYYY')}`;
    }

    return (
      <div className="fm-incident-view">
        <NavigationBar title={title} loading={loading} showBack={true} rightActions={[downloadAction, editAction, refreshAction]} />
        <div className="fm-incident-view-content">
          <div className="fm-incident-view-details">

            <div className="fm-incident-view-relations">
              {
               (incident.type || incident.status || incident.region) &&
                <div className="fm-incident-view-relation">
                  <table className="fm-incident-view-relation-table">
                    <tbody>
                      {
                        incident.type &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label"><span className="fm-incident-view-relation-dot" style={{ backgroundColor: colors.incident[incident.type] }}></span>{ types[incident.type] }{ incident.severity ? ` (${incident.severity})` : null }</td>
                        </tr>
                      }
                      {
                        incident.status &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-value"><span className="fm-incident-view-relation-dot" style={{ backgroundColor: statuses[incident.status].color }}></span>{ statuses[incident.status].label }</td>
                        </tr>
                      }
                      {
                        incident.validity === 'falseAlarm' ? (
                          <tr className="fm-incident-view-relation-row">
                            <td className="fm-incident-view-relation-value"><span className="fm-incident-view-relation-dot"></span>False Alarm</td>
                          </tr>
                        ) : (
                          <tr className="fm-incident-view-relation-row">
                            <td className="fm-incident-view-relation-value"><span className="fm-incident-view-relation-dot" style={ incident.insightsVisible ? { backgroundColor: '#4B7BEC' } : {} }></span>{ incident.insightsVisible ? 'Visible on Insights' : 'Not on Insights' }</td>
                          </tr>
                        )
                      }
                      {
                        incident.region &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-value"><span className="fm-incident-view-relation-dot"></span>{ incident.region.name }</td>
                        </tr>
                      }
                      {
                        ['incident','nearMiss'].includes(incident.type) &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-value"><span className="fm-incident-view-relation-dot"></span>{ incident.peopleInvolved || 1 } { incident.peopleInvolved > 1 ? 'People' : 'Person' } { incident.involvedPedestrian ? '(Pedestrian)' : null }</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              }
              {
               (incident.name || incident.email || incident.phone) &&
                <div className="fm-incident-view-relation">
                  <table className="fm-incident-view-relation-table">
                    <tbody>
                      {
                        incident.name &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label">Name:</td>
                          {
                            incident.user ? (
                              <td className="fm-incident-view-relation-value"><Link className="fm-incident-view-relation-link" to={{ pathname: `/user/${incident.user.id}`, state: { user: incident.user } }}>{ incident.name }</Link></td>
                            ) : (
                              <td className="fm-incident-view-relation-value">{ incident.name }</td>
                            )
                          }
                        </tr>
                      }
                      {
                        incident.email &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label">Email:</td>
                          <td className="fm-incident-view-relation-value">{ incident.email }</td>
                        </tr>
                      }
                      {
                        incident.phone &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label">Phone:</td>
                          <td className="fm-incident-view-relation-value">{ incident.phone }</td>
                        </tr>
                      }
                      {
                        incident.trip &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label">Trip:</td>
                          <td className="fm-incident-view-relation-value"><Link className="fm-incident-view-relation-link" to={{ pathname: `/trip/${incident.trip.id}`, state: { trip: incident.trip } }}>#{ incident.trip.id }</Link></td>
                        </tr>
                      }
                      {
                        incident.supportTicket &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label">Support:</td>
                          <td className="fm-incident-view-relation-value"><Link className="fm-incident-view-relation-link" to={{ pathname: `/support/ticket/${incident.supportTicket.id}`, state: { ticket: incident.supportTicket } }}>#{ incident.supportTicket.id }</Link></td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              }
              {
                (incident.vehicleRegistration || incident.vehicle) &&
                <div className="fm-incident-view-relation">
                  <table className="fm-incident-view-relation-table">
                    <tbody>
                      {
                        incident.vehicleRegistration &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label">Vehicle:</td>
                          {
                            incident.vehicle ? (
                              <td className="fm-incident-view-relation-value"><Link className="fm-incident-view-relation-link" to={{ pathname: `/vehicle/${incident.vehicle.id}`, state: { vehicle: incident.vehicle } }}>{ incident.vehicleRegistration } ({incident.vehicle.type})</Link></td>
                            ) : (
                              <td className="fm-incident-view-relation-value">{ incident.vehicleRegistration }</td>
                            )
                          }
                        </tr>
                      }
                      {
                        incident.vehicle &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label">Status:</td>
                          <td className="fm-incident-view-relation-value">{ incident.vehicle.status }</td>
                        </tr>
                        }
                      {
                        incident.vehicle &&
                        <tr className="fm-incident-view-relation-row">
                          <td className="fm-incident-view-relation-label">Last Inspxn:</td>
                          <td className="fm-incident-view-relation-value">{ moment(incident.vehicle.lastInspection).format('h:mma D/MM/YY') }</td>
                        </tr>
                      }
                      {
                        ['incident','nearMiss'].includes(incident.type) ? (
                          <tr className="fm-incident-view-relation-row">
                            <td className="fm-incident-view-relation-label">Flagged:</td>
                            <td className="fm-incident-view-relation-value">{ incident.maintenanceFlagged ? 'Yes' : 'No' }</td>
                          </tr>
                        ) : (
                          <tr className="fm-incident-view-relation-row">
                            <td className="fm-incident-view-relation-label">Returned to Service:</td>
                            <td className="fm-incident-view-relation-value">{ incident.returnedToService ? 'Yes' : 'No' }</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              }
            </div>
            {
              (incident.startTime && incident.endTime) &&
              <div className="fm-incident-view-duration">
                <div className="fm-incident-view-duration-inner">
                  <div className="fm-incident-view-duration-line"></div>
                  <p className="fm-incident-view-duration-time">{ moment(incident.startTime).format('h:mma D/MM/YY') }</p>
                  <p className="fm-incident-view-duration-value">{ moment(incident.endTime).diff(incident.startTime, 'minutes') } mins</p>
                  <p className="fm-incident-view-duration-time">{ moment(incident.endTime).format('h:mma D/MM/YY') }</p>
                </div>
              </div>
            }
          </div>
          {
            incident.outcome &&
            <div className="fm-incident-view-cause">
              <p className="fm-incident-view-subtitle">{ ['incident','nearMiss'].includes(incident.type) ? 'Root Cause' : 'Outcome' }</p>
              <p className="fm-incident-view-outcome">{ incident.outcome }</p>
            </div>
          }
          {
            incident.mitigation &&
            <div className="fm-incident-view-cause">
              <p className="fm-incident-view-subtitle">Mitigation</p>
              <p className="fm-incident-view-outcome">{ incident.mitigation }</p>
            </div>
          }
          {
            incident.location &&
            <div className="fm-incident-view-cause">
              <p className="fm-incident-view-subtitle">Location</p>
              <p className="fm-incident-view-outcome">{ incident.location }</p>
            </div>
          }
          {
            incident.weather &&
            <div className="fm-incident-view-cause">
              <p className="fm-incident-view-subtitle">Weather</p>
              <p className="fm-incident-view-outcome">{ incident.weather }</p>
            </div>
          }
          <div className="fm-incident-view-activity">
            <p className="fm-incident-view-subtitle">Activity</p>
            <div className="fm-incident-view-activity-feed">
              <div className="fm-incident-view-activity-feed-line"></div>
              { (incident.activity || []).map(this.renderActivity) }
            </div>
            <div className="fm-incident-view-activity-composer">
              <textarea className="fm-input fm-input-textarea fm-incident-new-textarea" value={activityContent} onChange={this.onContentChange} okey="details" disabled={loading}></textarea>
                <div className="fm-incident-view-activity-composer-controls">
                  <div className="fm-incident-view-activity-composer-left">
                    <label className="fm-input-checkbox-container">
                      <input className="fm-input fm-input-checkbox" disabled={loading} id="insightsCheckbox" type="checkbox" checked={activityInsights || false} okey="insightsVisible" onChange={this.onInsightsChange} />
                      <span className="fm-input-checkbox-checkmark"></span>
                    </label>
                    <label className="fm-incident-view-activity-composer-label fm-unselectable" htmlFor="insightsCheckbox">Include on Insights</label>
                  </div>
                  <FileUploader accept="images" type="inspection" onFinish={this.onFile} />
                  <button className="fm-incident-view-activity-composer-submit" onClick={this.handleSubmit} disabled={loading}>{ loading ? '...' : 'Submit' }</button>
              </div>
            </div>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default IncidentView;
