import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class VoucherDownload extends Component {
  constructor(props) {
    super(props);
    const preloadedVoucher = props.location.state ? props.location.state.voucher : false;
    this.state = {
      voucher: preloadedVoucher || {},
      templates: [],
      selectedTemplate: {},
      tags: {},
      loading: true,
      error: false,
    };

    this.loadVoucherAndTemplates = this.loadVoucherAndTemplates.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onChangeTemplate = this.onChangeTemplate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderTag = this.renderTag.bind(this);
  }

  componentDidMount() {
    document.title = 'Voucher Download | Flamingo Admin';
    this.loadVoucherAndTemplates();
  }

  loadVoucherAndTemplates() {
    return api.get(`/voucher/${this.props.match.params.voucherId}`)
      .then((res) => this.setState({ voucher: { ...this.state.voucher, ...res.data.data }, tags: { terms: this.generateTerms(res.data.data) } }))
      .then(() => api.get(`/voucher/templates`))
      .then((res) => this.setState({ templates: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  onDownload() {
    this.setState({ loading: true });
    const { selectedTemplate, tags } = this.state;
    const tagKeys = Object.keys(tags);
    const voucherData = {
      voucherTemplateId: selectedTemplate.id,
      tags: tagKeys.map((t) => ({ tag: t, value: tags[t] })),
    };
    return api.post(`/voucher/${this.props.match.params.voucherId}/download`, voucherData)
      .then(this.handleFile)
      .catch(this.handleError);
  }

  handleFile(res) {
    const isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);
    this.setState({ loading: false }, () => isIOS ? (window.location = res.data.data.url) : window.open(res.data.data.url));
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? `Invalid ${e.response.data.validation[0]}` : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, loading: false });
  }

  onChangeTemplate(e) {
    const { templates, tags } = this.state;
    const selectedTemplate = templates.find((t) => t.id === parseInt(e.target.value));
    const imageTags = selectedTemplate.tags.filter((t) => t.type === 'image');
    for (var i = 0; i < imageTags.length; i++) {
      if (selectedTemplate.images && selectedTemplate.images[0]) {
        tags[imageTags[i].tag] = selectedTemplate.images[0].imageUrl;
      }
    }
    this.setState({ selectedTemplate, tags, error: false })
  }

  onChange(e) {
    const { tags } = this.state;
    tags[e.target.getAttribute('okey')] = e.target.value;
    this.setState({ tags, error: false })
  }

  generateTerms(voucher) {
    const termsArray = [
      'Limit one per customer.',
      'Redeem in the Payment tab of the Flamingo app.',
    ];
    if (voucher.region) {
      termsArray.push(`Only valid for trips in ${voucher.region.name}.`);
    }
    if (voucher.vehicleType) {
      termsArray.push(`Only valid for trips on Flamingo ${voucher.vehicleType.toLowerCase()}s.`);
    }
    termsArray.push(`Valid until ${moment(voucher.expires).format('D MMMM YYYY')}, subject to availability.`);
    if (voucher.type === 'MINUTES') {
      termsArray.push('A $1 unlocking fee applies.');
    }
    termsArray.push(`This voucher has no cash value and cannot be exchanged or transferred.`);
    return termsArray.join(' ');
  }

  renderTag(tag, i) {
    const { tags, selectedTemplate, loading } = this.state;
    if (tag.type === 'image') {
      const imgValue = tags[tag.tag] || '-1';
      return (
        <div className="fm-voucher-download-input" key={i}>
          <p className="fm-input-label">{tag.name}</p>
          <select value={imgValue} disabled={loading} onChange={this.onChange} okey={tag.tag} className="fm-input fm-input-select">
            <option disabled value="-1">Select an image</option>
            { selectedTemplate.images && selectedTemplate.images.map((image, i) => <option value={image.imageUrl} key={i}>{ image.name }</option>) }
          </select>
        </div>
      );
    }
    const value = tags[tag.tag] || '';
    return (
      <div className="fm-voucher-download-input" key={i}>
        <p className="fm-input-label">{tag.name}</p>
        <input value={value} onChange={this.onChange} okey={tag.tag} placeholder={tag.placeholder} className="fm-input" disabled={loading} />
      </div>
    );
  }

  render() {
    const {
      loading,
      error,
      voucher,
      templates,
      selectedTemplate,
    } = this.state;

    return (
      <div className="fm-voucher-download">
        <NavigationBar title="Voucher Download" subtitle={voucher.code} loading={loading} showBack={true} />
        <div className="fm-voucher-download-form">
          <div className="fm-voucher-download-input">
            <p className="fm-input-label">Template</p>
            <select value={selectedTemplate.id} disabled={loading} onChange={this.onChangeTemplate} defaultValue="-1" className="fm-input fm-input-select">
              <option disabled value="-1">Select a template</option>
              { templates.map((template, i) => <option value={template.id} key={i}>{ template.name }</option>) }
            </select>
            { selectedTemplate.printNotes && <p className="fm-input-hint">{ selectedTemplate.printNotes }</p> }
          </div>
          { selectedTemplate.tags && selectedTemplate.tags.map(this.renderTag) }
          <button className="fm-voucher-download-button" onClick={this.onDownload} disabled={!selectedTemplate.id || loading}>{ loading ? 'Creating...' : 'Download' }</button>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default VoucherDownload;
