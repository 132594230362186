import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Map, { Rectangle, Circle, Polyline } from 'components/common/map';
import api from 'helpers/api';
import user from 'helpers/user';

const colors = ['#00a8ff', '#fbc531', '#9c88ff', '#4cd137', '#e84118', '#487eb0', '#f0932b', '#273c75', '#be2edd', '#353b48', '#ED4C67', '#006266', '#009432', '#EE5A24'];

class TripSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, results: [], regions: [], type: 'start', regionId: 1, now: moment().format('YYYY-MM-DDTHH:mm') };

    this.search = this.search.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleClick = this.handleClick.bind(this);
    this.boundsChange = this.boundsChange.bind(this);
    this.resetBounds = this.resetBounds.bind(this);
    this.onStartChange = this.onStartChange.bind(this);
    this.onEndChange = this.onEndChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onRegionChange = this.onRegionChange.bind(this);

    this.renderResult = this.renderResult.bind(this);
  }

  componentDidMount() {
    document.title = `Trip Search | Flamingo Admin`;
    this.loadRegions();
    this.loadUser();
  }

  /* NETWORKING */

  loadRegions() {
    return api.get('/region/all')
      .then((res) => this.setState({ regions: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  loadUser() {
    user.getCurrentUser().then((user) => user.admin.region ? this.setState({ regionId: user.admin.region.id }) : {});
  }

  search() {
    const {
      type,
      regionId,
      startDate,
      endDate,
      bounds,
      loading,
      now,
    } = this.state;

    if (loading || !bounds || !startDate) {
      return;
    }

    this.setState({ loading: true });

    const data = {
      startDate: moment(startDate).utc().format(),
      endDate: moment(endDate || now).utc().format(),
      neLatitude: bounds.north,
      neLongitude: bounds.east,
      swLatitude: bounds.south,
      swLongitude: bounds.west,
      type,
      regionId,
    };

    return api.post(`/trip/search`, data)
      .then((res) => this.setState({ results: res.data.data, resultType: type, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  /* ACTIONS */

  handleClick(latitude, longitude) {
    if (this.state.bounds) {
      return;
    }
    const bounds = {
      north: latitude + 0.0005,
      south: latitude - 0.0005,
      east: longitude + 0.0005,
      west: longitude - 0.0005,
    };
    this.boundsChange(bounds);
  }

  boundsChange(bounds) {
    this.setState({ bounds });
  }

  resetBounds() {
    this.setState({ bounds: undefined, results: [] });
  }

  onStartChange(e) {
    const startDate = e.target.value;
    this.setState({ startDate });
  }

  onEndChange(e) {
    const endDate = e.target.value;
    this.setState({ endDate });
  }

  onTypeChange(e) {
    const type = e.target.value;
    this.setState({ type });
  }

  onRegionChange(e) {
    const regionId = parseInt(e.target.value);
    this.setState({ regionId });
  }

  /* RENDERS */

  renderResult(trip, i) {
    const { resultType } = this.state;

    return (
      <Link className="fm-trip-search-result" key={i} to={`/trip/${trip.id}`}>
        <div className="fm-trip-search-result-color" style={{ backgroundColor: colors[i] || '#000' }}></div>
        <div className="fm-trip-search-result-details">
          { resultType === 'start' && <p className="fm-trip-search-result-title">Started at { moment(trip.startTime).format('h:mma D/MM/YY') }</p> }
          { resultType === 'end' && <p className="fm-trip-search-result-title">Ended at { moment(trip.endTime).format('h:mma D/MM/YY') }</p> }
          { resultType === 'waypoint' && <p className="fm-trip-search-result-title">{ moment(trip.startTime).format('h:mma') } to { moment(trip.endTime).format('h:mma D/MM/YY') }</p> }
          <p className="fm-trip-search-result-description">{ trip.user.firstName } { trip.user.lastName } - { moment(trip.endTime).diff(trip.startTime, 'minutes') } mins</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, error, bounds, startDate, endDate, type, regions, regionId, now, results, resultType } = this.state;

    const actions = [];
    if (bounds) {
      actions.push({ onClick: this.resetBounds, icon: 'times' });
    }

    return (
      <div className="fm-trip-search">
        <NavigationBar title="Trip Search" loading={loading} rightActions={actions} />
        <div className="fm-trip-search-content">
          <div className="fm-trip-search-details">
            <div className="fm-trip-search-form">
              <div className="fm-trip-search-input-row">
                <div className="fm-trip-search-input">
                  <p className="fm-input-label">Start Time</p>
                  <input type="datetime-local" value={startDate || now} onChange={this.onStartChange} max={now} className="fm-input" disabled={loading} />
                </div>
                <div className="fm-trip-search-input">
                  <p className="fm-input-label">End Time</p>
                  <input type="datetime-local" value={endDate || now} onChange={this.onEndChange} max={now} className="fm-input" disabled={loading} />
                </div>
              </div>
              <div className="fm-trip-search-input-row">
                <div className="fm-trip-search-input">
                  <p className="fm-input-label">Type</p>
                  <select value={type} disabled={loading} onChange={this.onTypeChange} className="fm-input fm-input-select">
                    <option value="start">Trip Starts</option>
                    <option value="end">Trip Ends</option>
                    <option value="waypoint">Trip Waypoints</option>
                  </select>
                </div>
              </div>
              <div className="fm-trip-search-input-row">
                <div className="fm-trip-search-input">
                  <p className="fm-input-label">Region</p>
                  <select value={regionId} disabled={loading} onChange={this.onRegionChange} className="fm-input fm-input-select">
                    { regions.map((region, i) => <option value={region.id} key={i}>{region.name}</option>) }
                  </select>
                </div>
              </div>
              <button className="fm-trip-search-button" onClick={this.search} disabled={loading || !bounds}>{ loading ? 'Loading...' : (bounds ? 'Search' : 'Select Area on Map') }</button>
            </div>
            <div className="fm-trip-search-results">
              <p className="fm-trip-search-results-count">{ results.length === 1 ? 'Result' : 'Results' } ({ results.length })</p>
              { results.map(this.renderResult) }
            </div>
          </div>
          <div className="fm-trip-search-map">
            <Map google={window.google} onClick={this.handleClick} isGlobal hideLocation>
              { bounds && <Rectangle bounds={bounds} editable={true} fillOpacity={0.1} onChange={this.boundsChange} /> }
              { resultType === 'start' && results.map((trip, i) => <Circle key={i} position={{ lat: trip.startLatitude, lng: trip.startLongitude }} radius={10} strokeWeight={0} fillColor={colors[i] || '#000'} fillOpacity={0.75} />) }
              { resultType === 'end' && results.map((trip, i) => <Circle key={i} position={{ lat: trip.endLatitude, lng: trip.endLongitude }} radius={10} strokeWeight={0} fillColor={colors[i] || '#000'} fillOpacity={0.75} />) }
              { resultType === 'waypoint' && results.map((trip, i) => <Polyline key={i} encodedPath={trip.encodedPolyline} strokeColor={colors[i] || '#000'} strokeWeight={'3'} />) }
            </Map>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default TripSearch;
