import React, { Component } from 'react';
import { QrReader } from '@flamingo-scooters/react-qr-reader';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class AddVehicle extends Component {
  constructor() {
    super();
    this.state = {
      vehicle: {
        beamQrCode: '',
        qrCode: '',
        registration: '',
        aiBox: '',
      },
      check: {},
      uploadMode: false,
      loading: false,
    };

    this.change = this.change.bind(this);
    this.keyDown = this.keyDown.bind(this);

    this.handleScan = this.handleScan.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.beamCheck = this.beamCheck.bind(this);
    this.toggleUpload = this.toggleUpload.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleError = this.handleError.bind(this);

    this.inputBeamQr = React.createRef();
    this.inputQr = React.createRef();
    this.inputRegistration = React.createRef();
  }

  componentDidMount() {
    document.title = 'Add Beam | Flamingo Admin';
    this.inputBeamQr.current.focus();
  }

  handleScan(result, error) {
    const { vehicle, loading } = this.state;
    if (result && !loading) {
      const flamingoPattern = /^https:\/\/ride\.flamingoscooters\.com\/s\/\d{6}$/;
      const beamPattern = /^https:\/\/deeplink\.ridebeam\.com\/?\?code=[a-zA-Z0-9]{5,7}$/;
      if (flamingoPattern.test(result.text)) {
        const qrCode = result.text.slice(-6);
        if (!isNaN(qrCode) && vehicle.qrCode !== qrCode) {
          vehicle.qrCode = qrCode;
          this.setState({ vehicle });
        }
      } else if (beamPattern.test(result.text)) {
        const beamQrCode = result.text.split('=').pop();
        if (vehicle.beamQrCode !== beamQrCode) {
          vehicle.beamQrCode = beamQrCode;
          this.setState({ vehicle }, this.beamCheck);
        }
      }
    }
  }

  beamCheck() {
    const { vehicle } = this.state;
    this.setState({ check: {} });
    if (vehicle.beamQrCode.length >= 5) {
      return api.post('/vehicle/beam/check', vehicle)
        .then((res) => this.setState({ check: res.data.data }))
        .catch((e) => this.handleError(e, true));
    }
  }

  toggleUpload() {
    this.setState({ uploadMode: !this.state.uploadMode });
  }

  handleAdd() {
    this.setState({ loading: true, error: false });
    const { uploadMode } = this.state;
    const vehicle = Object.assign({}, this.state.vehicle);

    if (vehicle.beamQrCode.length < 5 || vehicle.beamQrCode.length > 7) {
      return this.setState({ loading: false, error: 'Invalid Beam QR Code' });
    }

    if (uploadMode) {
      if (vehicle.aiBox.length !== 12) {
        return this.setState({ loading: false, error: 'Invalid AI Box Serial Number' });
      }
    } else {
      if (vehicle.qrCode.length !== 6 || !/^\d+$/.test(vehicle.qrCode)) {
        return this.setState({ loading: false, error: 'Invalid QR code' });
      }
      if (vehicle.registration.length !== 4 || !/^\d+$/.test(vehicle.registration)) {
        return this.setState({ loading: false, error: 'Invalid registration' });
      }
    }

    const endpoint = uploadMode ? '/vehicle/beam/ai' : '/vehicle/beam';
    return api.post(endpoint, vehicle)
      .then((res) => uploadMode ? this.handleUploadComplete() : history.push(`/vehicle/${res.data.data.id}`))
      .catch(this.handleError);
  }

  handleUploadComplete() {
    const newState = {
      vehicle: {
        beamQrCode: '',
        qrCode: '',
        registration: '',
        aiBox: '',
      },
      check: {},
      loading: false,
    };
    this.setState(newState, () => alert('Data Upload Successful'));
  }

  handleError(e, isCheck = false) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    if (isCheck) {
      this.setState({ check: { error }, loading: false });
    } else {
      this.setState({ error, loading: false });
    }
  }

  change(key, e) {
    const vehicle = this.state.vehicle;
    vehicle[key] = e.target.value;
    if (key === 'aiBox' && e.target.value.includes('AI BOX SN')) {
      const match = e.target.value.match(/AI BOX SN:\s*([A-Za-z0-9]{12})/)
      if (match) {
        vehicle[key] = match[1];
      }
    }
    if (key === 'beamQrCode') {
      this.setState({ vehicle }, this.beamCheck);
    } else {
      this.setState({ vehicle });
    }
  }

  keyDown(key, e) {
    if (e.key === 'Enter') {
      switch (key) {
        case 'beamQrCode':
          return this.inputQr.current.focus();
        case 'qrCode':
          return this.inputRegistration.current.focus();
        default:
          this.handleAdd();
      }
    }
  }

  render() {
    const { loading, vehicle, check, uploadMode, error } = this.state;
    const { beamQrCode, qrCode, registration, aiBox } = vehicle;
    const uploadAction = { onClick: this.toggleUpload, icon: uploadMode ? 'plus-circle' : 'upload' };
    const helpAction = { to: { pathname: `/manual/67` }, icon: 'info-circle' };

    return (
      <div className="fm-beam">
        <NavigationBar title={ uploadMode ? 'Upload Beam Data' : 'Add Beam' } loading={loading} rightActions={[uploadAction, helpAction]} />
        <div className="fm-beam-content">
          <QrReader
            scanDelay={300}
            onResult={this.handleScan}
            constraints={{ facingMode: 'environment' }}
            videoStyle={{ objectFit: 'cover' }}
            className="fm-beam-scanner"
          />
          <div className="fm-beam-input">
            <p className="fm-input-label">Beam QR Code</p>
            <input ref={this.inputBeamQr} value={beamQrCode} disabled={loading} className="fm-input" onChange={(e) => this.change('beamQrCode', e)} onKeyDown={(e) => this.keyDown('beamQrCode', e)} />
          </div>
          {
            !uploadMode &&
            <div className="fm-beam-input">
              <p className="fm-input-label">QR Code</p>
              <input ref={this.inputQr} value={qrCode} disabled={loading} className="fm-input" onChange={(e) => this.change('qrCode', e)} onKeyDown={(e) => this.keyDown('qrCode', e)} pattern="\d*" maxLength={6} />
            </div>
          }
          {
            !uploadMode &&
            <div className="fm-beam-input">
              <p className="fm-input-label">Registration</p>
              <input ref={this.inputRegistration} value={registration} disabled={loading} className="fm-input" onChange={(e) => this.change('registration', e)} onKeyDown={(e) => this.keyDown('registration', e)} pattern="\d*" maxLength={4} />
            </div>
          }
          {
            uploadMode &&
            <div className="fm-beam-input">
              <p className="fm-input-label">AI Box SN</p>
              <input ref={this.inputAIBox} value={aiBox} disabled={loading} className="fm-input" onChange={(e) => this.change('aiBox', e)} onKeyDown={(e) => this.keyDown('aiBox', e)} pattern="\d*" placeholder="MTSXXXXXXXXX" />
            </div>
          }
          <button className="fm-task-new-button" disabled={loading} onClick={this.handleAdd}>{ loading ? 'Loading...' : (uploadMode ? 'Upload Data' : 'Add Vehicle') }</button>
          { check.error && <p className="fm-beam-check-error"><b>Pre-Check Error:</b> { check.error }</p> }
          { check.imei && <p className="fm-beam-check-info"><b>IMEI:</b> { check.imei }</p> }
          { check.macAddress && <p className="fm-beam-check-info"><b>MAC:</b> { check.macAddress.toUpperCase() }</p> }
          { check.model && <p className="fm-beam-check-info"><b>Model:</b> { check.model }</p> }
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default AddVehicle;
