import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import LoadingLine from 'components/common/loading-line';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';
import history from 'helpers/history';
import colors from 'helpers/colors';
import location from 'helpers/location';

const filterTypes = ['pickup', 'charge', 'batterySwap', 'rebalance', 'repark', 'inspection', 'helmet'];

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  'pickupSCOOTER': { url: baseUrl + 'task-pickup-scooter.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'batterySwapSCOOTER': { url: baseUrl + 'task-battery-swap-scooter.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'rebalanceSCOOTER': { url: baseUrl + 'task-rebalance-scooter.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'reparkSCOOTER': { url: baseUrl + 'task-repark-scooter.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'chargeSCOOTER': { url: baseUrl + 'task-charge-scooter.png?v3', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'inspectionSCOOTER': { url: baseUrl + 'task-inspection-scooter.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'helmetSCOOTER': { url: baseUrl + 'task-helmet-scooter.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'pickupBIKE': { url: baseUrl + 'task-pickup-bike.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'batterySwapBIKE': { url: baseUrl + 'task-battery-swap-bike.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'rebalanceBIKE': { url: baseUrl + 'task-rebalance-bike.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'reparkBIKE': { url: baseUrl + 'task-repark-bike.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'chargeBIKE': { url: baseUrl + 'task-charge-bike.png?v3', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'inspectionBIKE': { url: baseUrl + 'task-inspection-bike.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'helmetBIKE': { url: baseUrl + 'task-helmet-bike.png?v2', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'pickupSCOOTERpriority': { url: baseUrl + 'task-pickup-scooter-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'batterySwapSCOOTERpriority': { url: baseUrl + 'task-battery-swap-scooter-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'rebalanceSCOOTERpriority': { url: baseUrl + 'task-rebalance-scooter-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'reparkSCOOTERpriority': { url: baseUrl + 'task-repark-scooter-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'chargeSCOOTERpriority': { url: baseUrl + 'task-charge-scooter.png?v3', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'inspectionSCOOTERpriority': { url: baseUrl + 'task-inspection-scooter-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'helmetSCOOTERpriority': { url: baseUrl + 'task-helmet-scooter-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'pickupBIKEpriority': { url: baseUrl + 'task-pickup-bike-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'batterySwapBIKEpriority': { url: baseUrl + 'task-battery-swap-bike-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'rebalanceBIKEpriority': { url: baseUrl + 'task-rebalance-bike-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'reparkBIKEpriority': { url: baseUrl + 'task-repark-bike-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'chargeBIKEpriority': { url: baseUrl + 'task-charge-bike.png?v3', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'inspectionBIKEpriority': { url: baseUrl + 'task-inspection-bike-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
  'helmetBIKEpriority': { url: baseUrl + 'task-helmet-bike-priority.png', size: new window.google.maps.Size(34, 32), scaledSize: new window.google.maps.Size(34, 32), anchor: new window.google.maps.Point(16, 14) },
};

const taskIcons = {
  batterySwap: 'battery-full',
  rebalance: 'arrow-alt-circle-right',
  repark: 'chevron-circle-up',
  pickup: 'shuttle-van',
  inspection: 'tools',
  charge: 'bolt',
  deploy: 'external-link-square-alt',
  helmet: 'hard-hat',
};

const taskZIndex = {
  batterySwap: 9,
  rebalance: 5,
  repark: 4,
  pickup: 10,
  inspection: 8,
  charge: 6,
  deploy: 3,
  helmet: 7,
};

const cardKeys = {
  pendingTickets: { color: '#EB3B5A', label: 'Pending Tickets', background: true },
  offlineVehicles: { color: '#e67e22', label: 'Offline Vehicles', background: true },
  availableBatterySwapTasks: { color: '#4B7BEC', label: 'Battery Swap Tasks' },
  hubOccupancy: { color: '#f1c40f', label: 'Current Nest Targets', isPercentage: true },
  fallenVehicles: { color: '#f1c40f', label: 'Fallen Over Vehicles' },
  availablePickupTasks: { color: '#EB3B5A', label: 'Available Pickup Tasks' },
  availableChargeTasks: { color: '#1abc9c', label: 'Available Charge Tasks' },
  unlockedBikes: { color: '#833471', label: 'Unlocked Bikes' },
  unlockedHelmets: { color: '#A3CB38', label: 'Missing Helmets' },
};

class TaskMap extends Component {
  constructor() {
    super();
    this.state = { tasks: [], stats: [], types: ['pickup', 'charge', 'rebalance', 'repark', 'batterySwap', 'inspection', 'helmet'], loading: true };

    this.map = React.createRef();
    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.loadMapForBounds = this.loadMapForBounds.bind(this);

    this.loadFilters = this.loadFilters.bind(this);
    this.toggleType = this.toggleType.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleLocate = this.handleLocate.bind(this);
    this.handleLocation = this.handleLocation.bind(this);

    this.renderOption = this.renderOption.bind(this);
    this.renderCard = this.renderCard.bind(this);
  }

  componentDidMount() {
    document.title = 'Tasks | Flamingo Admin';
    localStorage.setItem('fm-task', 'map');
    this.loadFilters();
    this.loadStats();
  }

  /* ------ Stats ------ */

  loadStats() {
    api.get('/task/statistics')
      .then((res) => this.setState({ stats: Object.keys(res.data.data).map((key) => ({ key, value: res.data.data[key] })).filter((v) => v.value > 0) }))
      .catch(console.log);
  }

  handleCardClick(key) {
    switch (key) {
      case 'deployableVehicles':
        return history.push('/task/deployable');
      case 'transitVehicles':
        return history.push('/task/transits');
      case 'pendingTickets':
        return history.push('/ticket');
      case 'offlineVehicles':
        return history.push('/task/offline');
      case 'fallenVehicles':
        return history.push('/task/fallen/map');
      case 'unlockedBikes':
        return history.push('/task/unlocked');
      case 'unlockedHelmets':
        return this.setState({ types: ['helmet'], loading: true }, () => this.filterChange(['helmet']));
      case 'upcomingInspections':
        return history.push('/maintenance/schedule');
      case 'hubOccupancy':
        return history.push('/hub/map');
      case 'availablePickupTasks':
        return this.setState({ types: ['pickup'], loading: true }, () => this.filterChange(['pickup']));
      case 'availableBatterySwapTasks':
        return this.setState({ types: ['batterySwap'], loading: true }, () => this.filterChange(['batterySwap']));
      case 'availableChargeTasks':
        return this.setState({ types: ['charge'], loading: true }, () => this.filterChange(['charge']));
      default:
        return;
    }
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng(), this.state.types);
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude, types) {
    let endpoint = `/task/map?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}`;

    if (types && types.length > 0 && types.length !== filterTypes.length) {
      endpoint += types.map((type) => `&type[]=${type}`).join('');
    }

    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude };
    return api.get(endpoint)
      .then((res) => this.setState({ tasks: res.data.data, currentBounds, loading: false }))
      .catch(console.log);
  }

  handleLocate() {
    return location.getLocation(true)
      .then(this.handleLocation)
      .catch(console.log);
  }

  handleLocation(location) {
    this.map.current.setCentre(new window.google.maps.LatLng(location.latitude, location.longitude));
  }

  /* ------ Filters ------ */

  loadFilters() {
    const filtersString = localStorage.getItem('taskFilters');
    if (filtersString) {
      const types = JSON.parse(filtersString);
      this.setState({ types });
    }
  }

  toggleType(type) {
    const types = this.state.types;

    const typeIndex = types.indexOf(type);
    if (typeIndex > -1) {
      types.splice(typeIndex, 1);
      if (types.length === 0) {
        return;
      }
    } else {
      types.push(type);
    }

    this.setState({ types, loading: true }, () => this.filterChange(types));
    localStorage.setItem('taskFilters', JSON.stringify(types));
  }

  filterChange(types) {
    const cb = this.state.currentBounds;
    if (cb) {
      this.loadMapForBounds(cb.neLatitude, cb.neLongitude, cb.swLatitude, cb.swLongitude, types);
    }
  }

  /* ------ Renders ------ */

  renderOption(type, i) {
    const style = { color: '#222222', background: '#F8F7F9' };

    if (this.state.types.includes(type)) {
      style.background = colors.task[type];
      style.color = '#FFF';
    }

    return (
      <span
        className="fm-task-map-filters-type"
        key={i}
        style={style}
        onClick={() => this.toggleType(type)}
      >
        <Icon icon={taskIcons[type]} />
        { type.toUpperCase() }
      </span>
    );
  }

  renderCard(stat, i) {
    const card = cardKeys[stat.key];
    if (card) {
      return (
        <div className="fm-task-map-card" key={i} style={{ backgroundColor: card.background ? card.color : undefined }} onClick={() => this.handleCardClick(stat.key)}>
          <p className="fm-task-map-card-text" style={{ color: card.background ? undefined : card.color }}>{ stat.value }{ card.isPercentage ? '%' : null } - { card.label }</p>
        </div>
      );
    }
    return null;
  }

  render() {
    const { tasks, stats, loading } = this.state;
    const fallenMapAction = { to: '/task/fallen/map', icon: 'angle-double-down' };
    const listTaskAction = { to: '/task/active', icon: 'list' };
    const newTaskAction = { to: '/task/new', icon: 'plus-circle' };
    const newRouteAction = { to: '/task/route/new', icon: 'route' };
    const locateAction = { onClick: this.handleLocate, icon: 'location-arrow' };

    return (
      <div className="fm-task-map">
        <NavigationBar title="Tasks" rightActions={[locateAction, fallenMapAction, newRouteAction, listTaskAction, newTaskAction]} />
        {
          stats.length > 0 &&
          <div className="fm-task-map-cards">
            { stats.map(this.renderCard) }
          </div>
        }
        <div className="fm-task-map-holder">
          <Map google={window.google} ref={this.map} onBoundsChange={this.handleMapBoundsChange} isGlobal>
            { tasks.map((task, i) => <Marker key={i} position={{ lat: task.vehicle.latitude, lng: task.vehicle.longitude }} title={`${task.vehicle.registration} - ${task.type.toUpperCase()} (${parseInt(task.vehicle.batteryPercent * 100)}%)`} icon={icons[task.type + task.vehicle.type + (task.priority > 1 ? 'priority' : '')]} zIndex={taskZIndex[task.type]} onClick={() => history.push({ pathname: `/vehicle/${task.vehicle.id}`, state: { vehicle: task.vehicle } })} />) }
          </Map>
        </div>
        <div className="fm-task-map-filters">
          { filterTypes.map(this.renderOption) }
        </div>
        <LoadingLine hide={!loading} />
      </div>
    );
  }
}

export default TaskMap;
