import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class AnalyticsSafety extends Component {
  constructor() {
    super();
    const today = moment().format('YYYY-MM-DD');
    this.state = { date: today, today, data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Health and Safety | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const date = this.state.date;
    return api.post('/analytics/safety', { date })
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleDateChange(e) {
    this.setState({ date: e.target.value });
  }

  render() {
    const { loading, data, date, today } = this.state;

    return (
      <div className="fm-analytics">
        <NavigationBar title="Health and Safety" loading={loading} />
        <div className="fm-analytics-safety">
          <div className="fm-analytics-safety-form">
            <input value={date} className="fm-input" type="date" onChange={this.handleDateChange} max={today} />
            <button className="fm-analytics-safety-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Load Data' }</button>
          </div>
          {
            data ? (
              <table className="fm-analytics-safety-table">
                <tbody>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Period</td>
                    <td className="fm-analytics-safety-table-value">{ moment(data.startDate).format('DD-MM-YYYY') } to { moment(data.endDate).format('DD-MM-YYYY') }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Total Trips</td>
                    <td className="fm-analytics-safety-table-value">{ data.trips.toLocaleString() }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Total Incidents</td>
                    <td className="fm-analytics-safety-table-value">{ data.incidents }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Incidents per Trip</td>
                    <td className="fm-analytics-safety-table-value">{ data.incidentsPerTrip }%</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Incident Resolution Time</td>
                    <td className="fm-analytics-safety-table-value">{ data.incidentsAvgOpen } days</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Safety Events</td>
                    <td className="fm-analytics-safety-table-value">{ data.safetyEvents }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Average Rider Rating</td>
                    <td className="fm-analytics-safety-table-value">{ data.ratingAverage }/5</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Total Support Tickets</td>
                    <td className="fm-analytics-safety-table-value">{ data.supportTickets.toLocaleString() }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Percent of Trips without Support Ticket</td>
                    <td className="fm-analytics-safety-table-value">{ data.tripsWithoutSupportTickets }%</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Percent of Trips Without an App Support Ticket</td>
                    <td className="fm-analytics-safety-table-value">{ data.tripsComparedToAppSupportTickets }%</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Support Ticket Resolution Time</td>
                    <td className="fm-analytics-safety-table-value">{ data.supportTicketResolution } hours</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">AC Complaints</td>
                    <td className="fm-analytics-safety-table-value">{ data.councilComplaints.auckland }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">WCC Complaints</td>
                    <td className="fm-analytics-safety-table-value">{ data.councilComplaints.wellington }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">WDC Complaints</td>
                    <td className="fm-analytics-safety-table-value">{ data.councilComplaints.waimakariri }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">PCC Complaints</td>
                    <td className="fm-analytics-safety-table-value">{ data.councilComplaints.porirua }</td>
                  </tr>
                  <tr>
                    <td className="fm-analytics-safety-table-label">Total Council Complaints</td>
                    <td className="fm-analytics-safety-table-value">{ data.councilComplaints.total }</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <p className="fm-analytics-safety-empty">Select a date from the quarter above.</p>
            )
          }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsSafety;
